<template>
  <!-- BLANK LAYOUT -->
  <main>
    <router-view />
  </main>
</template>

<script setup>
import Navbar from "@/views/_layout/Navbar.vue";
import { onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

onMounted(() => {
  // console.log("mounted...");
});
</script>

<style lang="scss" scoped>
main {
  //background-color:#eee;
  min-height: 100%;
}
</style>
