<template>
  <Navbar />
  <main>
    <div class="map-view-container">      
      <div class="split-view-holder">
        <AccountLeafletView ref="rMapView" />

        <div :class="['account-view-main-holder']">
          <NavbarAccount />
          <div class="router-view-holder">
            <router-view v-slot="{ Component }">
              <!-- <keep-alive> -->
                <component :is="Component" />
              <!-- </keep-alive> -->
            </router-view>
            <br /><br />
          </div>
          <div class="footer-holder">
            <Footer />
          </div>
        </div>
      </div>
    </div>

    <div id="overlay-template"></div>
  </main>
</template>

<script setup>
import Navbar from "@/views/_layout/Navbar.vue";
import NavbarAccount from "@/views/_layout/NavbarAccount.vue";
import AccountLeafletView from "@/views/Account.Leaflet.vue";
import Footer from "@/views/_layout/Footer.vue";
import { onMounted, onBeforeMount, computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

</script>

<style lang="scss" scoped>
.split-view-holder {
  max-height: calc(
    100vh - #{$nav-bar-height + $nav-bar-secondary-height}
  ) !important;
  //background-color: aquamarine;
}

.account-view-main-holder {
  //overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: $nav-bar-height + $nav-bar-secondary-height;
  min-height: 100%;
  //max-height:calc(100vh - #{$nav-bar-height + $nav-bar-secondary-height});
  //background-color: red;
  position: absolute;
  padding: 0 8px 0 8px;
  width: 50%;

  .router-view-holder {
    position: relative;
    display: block;
    padding: 10px 8px;
    width: 100%;
    //min-height: calc(100vh - 156px);
    //background-color: burlywood;
  }

  .footer-holder {
    position: relative;
    height: 10px;
    // /overflow-x: hidden;
    .footer {
      //loynote:ugly shat
      left: unset;
    }
  }


}
</style>

<style lang="scss" >
// @import "@/styles/map.scss";
</style>
