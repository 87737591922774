<template>
<!-- {{cUser}} -->
  <nav class="account">
    <div class="nav-holder">
      <TabMenu class="nav-tab-menu" :model="items" />
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import TabMenu from "primevue/tabmenu";
import env from "@/helpers/Environment";
import { useRoute } from "vue-router";

const store = useStore();
const cUser = computed(() => store.state.account.user);
const route = useRoute();

let items = [
  { label: "Profile", icon: "pi pi-fw pi-cog", to: "/account/profile" },
  {
    label: "Favourite",
    icon: "pi pi-fw pi-bookmark",
    to: "/account/favourite",
  },
  { label: "My listing", icon: "pi pi-fw pi-calendar", to: "/account/listing" },
  // {label: 'Browse History', icon: 'pi pi-fw pi-pencil', to:"/account/browse-history" },
  { label: "Message", icon: "pi pi-fw pi-file", to: "/account/message" },
  
];
if (cUser.value.roleAccess.admin) {
  items.push({ label: "Admin", icon: "pi pi-fw pi-prime", to: "/account/admin/listing" });
}

onMounted(() => {
  // console.log("route", route);
});

const onClickSignIn = () => {
  //console.log(env.AWS_COGNITO_SIGNIN_URL);
  window.location = env.AWS_COGNITO_SIGNIN_URL;
};

const onLogout = () => {
  store.dispatch("account/logout");
};
</script>

<style lang="scss" scoped>
nav.account {
  position: fixed;
  top: $nav-bar-height;
  height: $nav-bar-secondary-height;
  width: 100%;
  z-index: 1000;
  //background-color: white;
  //border-bottom: 1px solid $grey-6;
  .nav-holder {
    //display: flex;
    //max-width:1600px;
    margin: auto;
    height: auto;
    //padding-bottom: 8px;
  }
}

::v-deep(.p-tabmenu.nav-tab-menu) {
  .p-tabmenu-nav {
    border-width: 0 0 1px 0;
    .p-tabmenuitem {
      border-width: 0 0 2px 0;
      font-size: 0.95em;
      a {
        font-weight: normal;
        padding: 0.6rem 1rem;
        &:hover {
          text-decoration: none;
        }
        .p-menuitem-icon {
          margin-right: 0.2rem;
        }
        .p-menuitem-text {
          white-space: nowrap;
        }
      }
      &.p-highlight {
        a {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
