import { useGtm } from '@gtm-support/vue-gtm';

// place share functions project here
export default {
  track: (event, target, targetProperty,value, action="click") => {
    const gtm = useGtm();
    gtm.trackEvent({
      event: event,
      category: target,
      action: action,
      label: targetProperty,
      value: value,
      noninteraction: false,
    });
  }
};
