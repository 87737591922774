import  Joi from 'joi';
import _ from 'lodash';

const opts = { abortEarly: false, stripUnknown: true };
const Joi2 = Joi.defaults((schema) => schema.options(opts));

const Joi3 = Joi2.extend((joi) => ({
  base: joi.array(),
  // coerce: (value, helpers) => ({
  //   value: value.split ? value.split(',') : value,
  // }),
  coerce: (value, helpers) => {
    // console.log("customJoi", value, helpers);
    const arr = value.split(',');
    const sorted = _.sortBy(arr);
    return { value: sorted };
  },
  type: 'stringArray',
}));

export const customJoi = Joi3.extend((joi) => ({
  base: joi.array(),
  coerce: (value, helpers) => {
    // console.log("customJoi", value, helpers);
    const arr = value.split(',').filter((x) => x.trim().length && !isNaN(x)).map(Number);
    const sorted = _.sortBy(arr);
    return { value: sorted };
  },
  type: 'numberArray',
}));

export const getJoiData = (schema, data) => {
  // console.log("getJoiData", data);
  let j = null;
  /*
  if (schema instanceof Joi.constructor) {
    j = schema
  } else {
    j = customJoi.object(schema);
  }
  */
  j = customJoi.object(schema);
  // console.log("getJoiData", j)
  // const { value, error } = customJoi.object(schema).validate(data);
  const { value, error } = j.validate(data);
  return { value, error };
};

export const getJoiDataAsync = async (schema, data) => {
  // console.log("getJoiDataAsync", data);
  let value; let
    error = null;
  try {
    value = await customJoi.object(schema).validateAsync(data);
  } catch (err) {
    error = err;
  }
  return { value, error };
};

//export const customJoi2 = customJoi;

// loynote:: same func are found in backend /middlewares/errors.js

export const prepareJoiErrorArr = (err) => {
  const keys = _.map(err.details, 'context.key');
  const messages = _.map(err.details, 'message');
  // const data = _.map(err.details, 'data')
  console.log('prepareJoiErrorArr', err.details);
  const arr = keys.map((key, i) => ({ field: `${key}`, message: messages[i] }));
  return arr;
};
export const prepareApiErrorArr = (err) => err.errArr;
