import _ from 'lodash';

const prepareDropDown = (arr, arrValue = null) => {
  let o = [];
  if (arrValue) {
    o = arr.map((v, idx) => {
      // console.log(v, idx, arrValue);
      // loynote: for 5+ || $1.2m don't use start case
      const n = v.indexOf('+') != -1 || v.indexOf('$') != -1 ? v : _.startCase(v);
      return { name: n, value: _.toLower(arrValue[idx]) };
    });
  } else {
    o = arr.map((v) => ({ name: _.startCase(v), value: _.toLower(v) }));
  }
  //console.log("prepareDropDown", o);
  return o;
};

const addDisableOptions = (disabledFields, arr) => {
  disabledFields.forEach((s) => {
    _.mapValues(arr, (val, key) => {
      // val.disabled = false;
      if (val.value == s) {
        val.disabled = true;
      }
    });
  });
  return arr;
};

export { prepareDropDown, addDisableOptions };
