<template>
  <div class="map-view-holder account">
    <div class="banner" v-if="rShowBanner">
      <i class="ico logo-rwr-1 logo"></i>
    </div>
    <div class="map-view" id="account-view-id" />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref, onUpdated } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Constant from "@/helpers/Constant";
import Utils from "@/helpers/Utils";
import router from "@/router";
import { listingService } from "@/services/service.listing";
import { addMaskComponent } from "@/components/LeafletMask";
import polyUtil from "polyline-encoded";
import { thirdPartyService } from "@/services/service.thirdParty";
import MapFunc from "@/helpers/MapFunc";
import { showMessage } from "@/components/Notification";
import L from "leaflet";

const MAP_DEFAULT_ZOOM = 11.7;
const MAP_LISTING_ZOOM = 15;
const MAP_LISTING_DETAIL_ZOOM = 17;
const store = useStore();
const rShowBanner = ref(true);

let map = null;
let markerLayer = null;
let listings = null;
const m_arr = [];
let unsub1 = null;
let unsub2 = null;

onUpdated(() => {
  // tt = document.getElementById("tooltip-district");
});

onBeforeUnmount(() => {
  unsub1();
  unsub2();
});

onMounted(async () => {
  // await nextTick();
  map = L.map("account-view-id", { zoomDelta: 0.25, zoomSnap: 0 });
  setupMap(map);

  markerLayer = new L.layerGroup();
  markerLayer.addTo(map);
  subsribeStore();
});

const subsribeStore = () => {
  unsub1 = store.subscribeAction(async (action, state) => {
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
    if (action.type == "myListing/selectPostcode") {
      const listing = action.payload;
      const id = `new_listing_${new Date().getTime()}`;
      const str = `_${id}`;
      const myIcon = MapFunc.generateMarker(id, "selected");
      const { lat } = listing;
      const { lng } = listing;
      const latlng = L.latLng(lat, lng);
      const m = L.marker(latlng, {
        icon: myIcon,
        riseOnHover: true,
        riseOffset: 1000,
      });
      m.latlng = latlng;
      markerLayer.clearLayers();
      m.addTo(markerLayer);
      await nextTick();
      // console.log("markerLayer", markerLayer, m, latlng, listing);
      document.getElementById("anim_icon_over_01" + str)?.beginElement();
      map.flyTo(m.latlng, MAP_LISTING_DETAIL_ZOOM);
      rShowBanner.value = false;

      // const html = MapFunc.generateListingOverlay2(listing, id, "bottom")
      // document.getElementById("listing-overlay"+str).innerHTML = html;
    } else if (action.type == "map/resetAccountMap") {
      markerLayer.clearLayers();
      listings = null;
      map.setView(
        [Constant.SINGAPORE_CENTER[0], Constant.SINGAPORE_CENTER[1]],
        MAP_DEFAULT_ZOOM
      );
      rShowBanner.value = true;
    } else if (action.type == "map/onMouseEnterCard") {
      const l = action.payload;
      const m = _.find(m_arr, { listingId: l.listingId });
      m?.setZIndexOffset(-1000);
      const str = `_${l.listingId}`;
      try {
        document.getElementById(`anim_icon_over_01${str}`).beginElement();
      } catch (e) {
        //console.log(e);
      }
      // document.getElementById("anim_icon_over_01"+str).beginElement();
    } else if (action.type == "map/onMouseLeaveCard") {
      const l = action.payload;
      const str = `_${l.listingId}`;
      try {
        document.getElementById(`anim_icon_out_01${str}`).beginElement();
      } catch (e) {
        //console.log(e);
      }
      // document.getElementById("anim_icon_out_01"+str).beginElement();
    }
  });

  unsub2 = store.subscribe((mutation, state) => {
    //console.log("mutation.type",mutation.type,mutation.payload)
    if (
      mutation.type == "myListing/updateListing" ||
      mutation.type == "profile/updateFavouriteListingData"
    ) {
      const geojson = listingService.convertGeoJson(mutation.payload);
      if (!geojson) return null;
      if (geojson.features.length > 0) {
        rShowBanner.value = false;
        addListings(geojson);
      }
    } else if (mutation.type == "myListingDetail/updateListingDetail") {
      // reactiveData.listingDetail = mutation.payload;
      // rListingDetail.value = mutation.payload;
    }
  });
};

const setupMap = (map) => {
  const m = map;
  // amenityLayer = L.featureGroup();

  const onemap = L.tileLayer(
    "https://maps-{s}.onemap.sg/v3/Default_HD/{z}/{x}/{y}.png",
    {
      subdomains: ["a", "b", "c"],
      detectRetina: true,
      maxZoom: 20,
      minZoom: 11,
    }
  );
  const googleStreets = L.tileLayer(
    "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] }
  );
  const googleHybrid = L.tileLayer(
    "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
    { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] }
  );
  const googleSat = L.tileLayer(
    "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] }
  );
  const googleTerrain = L.tileLayer(
    "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] }
  );
  const openStreetMap = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    { maxZoom: 20 }
  );
  const cartoMap = L.tileLayer(
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    { maxZoom: 20 }
  );

  const baseMaps = {
    "G Streets": googleStreets,
    "G Sat": googleSat,
    "One Map": onemap,
    OSM: openStreetMap,
    Carto: cartoMap,
  };

  m.addLayer(cartoMap);
  // m.addLayer(googleStreets);
  // m.addLayer(openStreetMap);

  L.control.layers(baseMaps, null, { position: "bottomright" }).addTo(m);
  m.zoomControl.setPosition("bottomright");
  L.control.scale({ imperial: false }).addTo(map);

  m.setView(
    [Constant.SINGAPORE_CENTER[0], Constant.SINGAPORE_CENTER[1]],
    MAP_DEFAULT_ZOOM
  );
  // m.addLayer(amenityLayer);
};

var geojsonFeature = {
    "type": "Feature",
    "properties": {
        "name": "Coors Field",
        "amenity": "Baseball Stadium",
        "popupContent": "This is where the Rockies play!"
    },
    "geometry": {
        "type": "Point",
        "coordinates": [-104.99404, 39.75621]
    }
};

const addListings = (geojson) => {
  const listings = geojson.features;
  //console.log("account leaflet addListings", listings)
  //_.orderBy(listings, (f) => f.properties.updatedAt, ["desc"]);
  const geoJsonLayer = L.geoJson(listings);
  markerLayer.clearLayers();
  geoJsonLayer.eachLayer((layer) => {
    // markers
    if (layer.feature.geometry && layer.feature.geometry.type == "Point") {
      const ld = layer.feature.properties;
      const id = ld.listingId;
      const { offerType } = ld;
      const myIcon = MapFunc.generateMarker(id, offerType);
      const lat = layer.feature.geometry.coordinates[1];
      const lng = layer.feature.geometry.coordinates[0];

      const latlng = L.latLng(lat, lng);
      const m = L.marker(latlng, {
        icon: myIcon,
        riseOnHover: true,
        riseOffset: 1000,
      });
      // const m = L.marker(latlng, {icon:myIcon, riseOnHover:true});
      // const m = L.marker(latlng, {icon:myIcon});
      m.latlng = latlng;
      m.listingId = id;

      // const m = L.marker(latlng);
      m.addTo(markerLayer);

      //console.log("m",m);
      try {
        const str = `_${id}`;
        const holder = document.getElementById(`listing-marker-holder${str}`);
        const hotspot = holder.lastElementChild;
        // console.log("hotspot", hotspot, id)
        // hotspot.mouseover = (e) => { console.log("mouse move", id); mouseenterFeature(e, id) };z
        hotspot.onmouseenter = (e) => {
          MapFunc.mouseenterFeature(e, id, listings);
        };
        holder.onmouseleave = (e) => {
          MapFunc.mouseleaveFeature(e, id);
        };
        holder.ondblclick = (e) => {
          const event = e || window.event;
          event.stopPropagation();
        };
        // hotspot.onclick = (e) => { clickListing(e, id, m) };
        m.addEventListener("click", (e) => {
          try {
            const t = e.originalEvent.target;
            const t2 = e.originalEvent.srcElement;
            // console.log("m.addEventListener", t, t2);
            if (
              t.classList.contains("detail") ||
              t.classList.contains("hotspot") ||
              t.classList.contains("bottom") ||
              t.classList.contains("triangle") ||
              t.classList.contains("center")
            ) {
              // clickListing(m)
              const str = `/${ld.offerType}/${ld.marketType}/${ld.slug}/listing/${ld.listingId}`;
              // console.log("str", str)
              router.push(str);
            }
          } catch (e) {}
        });

        m_arr.push(m);
      } catch (e) {
        console.log("err", e);
      }
    }
  });
};
</script>

<style lang="scss" scoped>
.banner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  background-color: white;
  box-shadow: 1px 1px 5px 5px #00000011;
  border-radius: 8px;
  height: 150px;
  width: 320px;
  margin-top: -75px;
  margin-left: -160px;
  opacity: 0.7;
  .logo {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
</style>
