import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import * as Listing from '@/models/model.listing';
import { customJoi } from '@/models/customJoi';
import store from './stores';

const routes = [
  /**/
  {
    path: '/',
    name: 'index',
    //redirect: '/sale/residential'
    redirect: (to) => {
      const f = store.state.listing.filterData;
      const str = `/${f.offerType}/${f.marketType}/${f.propertyType}`;
      return { path: str };
    },
    //component: () => import('./views/Map.Listing.vue'),
    meta: { layout: "main" },
    alias: ["/sale","/sale/residential","/rental","/rental/residential"],
  },
  {
    path: '/:pOfferType/:pMarketType/:pPropertyType',
    name: 'map',
    component: () => import('./views/Map.Listing.vue'),
    meta: { layout: 'main' },
  },
  {
    // path: '/district/:pOfferType/:pMarketType/:pDistrict',
    // path: '/district/:pId',
    path: '/:pOfferType/:pMarketType/:pPropertyType/district/:pDistrict',
    name: 'district-listing',
    component: () => import('./views/Map.Listing.vue'),
    meta: { layout: 'main', scrollPos: { top: 0, left: 0 } },
  },
  {
    // path: '/listing/:pOfferType/:pMarketType/:pSlug/:pListingId',
    // path: '/listing/:pSlug/:pId/',
    path: '/:pOfferType/:pMarketType/:pPropertyType/:pSlug/listing/:pListingId',
    name: 'listing-detail',
    component: () => import('./views/Map.ListingDetail.vue'),
    meta: { layout: 'main' },
    props: { pAdminMode: false},
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('./views/Account.SignIn.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/account/favourite',
    name: 'favourite',
    component: () => import('./views/Account.Favourite.vue'),
    meta: { layout: 'account', requireAuth: true },
  },
  {
    path: '/account/listing',
    name: 'listing',
    component: () => import('./views/Account.Listing.vue'),
    meta: { layout: 'account', requireAuth: true },
  },
  {
    path: '/account/listing/new-sale',
    name: 'listing-new-sale',
    component: () => import('./views/Account.ListingForm.vue'),
    meta: { layout: 'account', requireAuth: true },
    props: { pFormType: 'new', pOfferType: 'sale' },
  },
  {
    path: '/account/listing/new-rental',
    name: 'listing-new-rental',
    component: () => import('./views/Account.ListingForm.vue'),
    meta: { layout: 'account', requireAuth: true },
    props: { pFormType: 'new', pOfferType: 'rental' },
  },
  // {
  //   path: '/account/listing/new-partial-rental',
  //   name: 'listing-new-partial-rental',
  //   component: () => import('./views/Account.ListingForm.vue'),
  //   meta: { layout: 'account', requireAuth: true },
  //   props: { pFormType: 'new', pOfferType: 'partial rental' },
  // },
  {
    path: '/account/listing/:pListingId',
    name: 'listing-edit',
    component: () => import('./views/Account.ListingForm.vue'),
    meta: { layout: 'account', requireAuth: true },
    props: { pFormType: 'edit' },
  },
  {
    path: '/account/message',
    name: 'message',
    component: () => import('./views/Account.Message.vue'),
    meta: { layout: 'account', requireAuth: true },
  },
  {
    path: '/account/profile',
    name: 'profile',
    component: () => import('./views/Account.Profile.vue'),
    meta: { layout: 'account', requireAuth: true },
  },
  {
    path: '/account/logout',
    name: 'logout',
    component: () => import('./views/Account.Logout.vue'),
    meta: { layout: 'blank', requireAuth: true },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('./views/Site.AboutUs.vue'),
    //meta: { layout: 'main-branding' },
    meta: { layout: 'main' },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('./views/Site.Feedback.vue'),
    meta: { layout: 'simple' },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('./views/Site.Terms.vue'),
    meta: { layout: 'simple' },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('./views/Site.Privacy.vue'),
    meta: { layout: 'simple' },
  },

  {
    path: '/account/admin/listing',
    name: 'admin-listing',
    component: () => import('./views/Account.AdminListing.vue'),
    meta: { layout: 'account', requireAuth: true },
  },
  {
    path: '/account/admin/scrape',
    name: 'admin-scrape',
    component: () => import('./views/Account.AdminScrape.vue'),
    meta: { layout: 'account', requireAuth: true },
  },  
  {
    path: '/account/admin/listing/:pListingId',
    name: 'admin-listing-detail',
    //component: () => import('./views/Account.AdminListingDetail.vue'),
    component: () => import('./views/Map.ListingDetail.vue'),
    meta: { layout: 'main', requireAuth: true },
    props: { pAdminMode: true },
  },  

  {
    path: '/:catchAll(.*)*',
    name: 'bad-not-found',
    component: () => import('./views/Site.NotFound.vue'),
    meta: { layout: 'main' },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      const pos = savedPosition || to.meta?.scrollPos || { top: 0, left: 0 };
      setTimeout(() => {
        // console.log("savedPosition to ---->", to);
        resolve(pos);
      }, 200);
    });
  },
});

const doRouteInitDispatch = (route) => {
  console.log("doRouteInitDispatch", route.name);
  if (route.name == 'map') {
    store.dispatch('listing/clearDistrict');
    store.dispatch('map/setMapViewLevel', { level: 'overview', id: null });
  } else if (route.name == 'district-listing') {
    store.dispatch('map/setMapViewLevel', { level: 'district', id: route.params.pDistrict });
    store.dispatch('listing/searchDistrict', route.params.pDistrict);
  } else if (route.name == 'listing-detail') {
    store.dispatch('map/setMapViewLevel', { level: 'listing', id: route.params.pListingId });
    // store.dispatch("listing/getListingDetail", route.params.pListingId);
    store.dispatch('listingDetail/getListingDetail', route.params.pListingId);
  } else if (route.name == 'admin-listing-detail') {
    store.dispatch('map/setMapViewLevel', { level: 'listing', id: route.params.pListingId });
    store.dispatch('admin/getListingDetail', route.params.pListingId);
  }
  
  // console.log("router.currentRoute", route)
};

router.afterEach(async (to, from) => {
  // document.title = to.meta.title || 'Some Default Title';
  // console.log("to", to);
  if (to.meta.layout) {
    store.dispatch('site/setLayout', to.meta.layout);
  }
  if (to.path.indexOf('/account/' == 0)) {
    store.dispatch('map/resetAccountMap');
  }

  const mapInit = store.getters['map/isInit'];
  if (mapInit) {
    doRouteInitDispatch(to);
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.name == 'sign-in' || to.name == 'feedback' || to.name == 'terms'
    || to.name == 'privacy' || to.name == 'about-us' || to.name == 'bad-not-found') {
    return next();
  }
  //console.log("to", to);
  const init = store.getters['site/isInit'];
  if (!init) {
    const wasLoggedIn = store.getters['site/wasLoggedIn'];
    if (wasLoggedIn) {
      //await store.dispatch('account/resumeLogin');
      await store.dispatch('account/refreshToken');
    }    
    await store.dispatch('site/init');
  } else {
    // loynote:: check auth every new route
    // auth = await store.dispatch("account/checkAuth");
    // console.log("account/checkAuth", auth);
  }
  const isLoggedIn = store.getters['account/isLoggedIn'];

  if (to.meta.requireAuth) {
    if (!isLoggedIn) {
      // showMessage('Please login.', "danger");
      store.dispatch('site/promptLogin', true);
      return next({ path: '/' });
      // return next({path: '/', query:{ redirect:to.fullPath }});
    }
  }

  if (to.name == 'map' || to.name == 'district-listing' || to.name == 'listing-detail') {
    const data = { offerType: to.params.pOfferType, marketType: to.params.pMarketType, propertyType: to.params.pPropertyType };
    // console.log("store.state.listing.filterData", f.offerType);
    const j = customJoi.object(Listing.checkOfferMarketProperty());
    const { value, error } = j.validate(data);
    //console.log('doRouteDispatch', data, value, error);
    if (error) {
      return next({ params: { catchAll: 'not-found' }, name: 'bad-not-found' });
      // return next({params: {catchAll:to.path}, name:"bad-not-found"});
    }
    const f = store.state.listing.filterData;
    const f2 = store.state.listing.watcher;
    f.offerType = f2.offerType = to.params.pOfferType;
    f.marketType = f2.marketType = to.params.pMarketType;
    f.propertyType = f2.propertyType = to.params.pPropertyType;
    //console.log("why no update?", f);
    store.dispatch('site/setScreenCategory', "listing");
  } else {
    store.dispatch('site/setScreenCategory', null);
  }


  // console.log("router.beforeEach", store.getters["account/isLoggedIn"]);
  // console.log("router.beforeEach", store.getters["account/user"]);

  // console.log("from.meta?.scrollTop", from, window.scrollY)
  from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY);

  next();
});

// TODO: drop short lived cookie to determine if user is sending withCredential... maybe?

export default router;
