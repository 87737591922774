const mode = import.meta.env.MODE

let env = "local";
if (mode != "development") {
  env = mode;
} 
//const env = "local";
//const env = "stage";
//const env = "prod";
console.log("*** system running in env:", env, " ***");

let apiUrl = "", cognitoAuthUrl = "", cognitoClientId = "", cognitoRedirectUri = "", gtm = "";

if (env == "local") {
  apiUrl = "http://localhost:3001/v1/";
  //apiUrl = "http://192.168.1.24:3001/v1/";
  //apiUrl = "http://192.168.1.137:3001/v1/";
  cognitoAuthUrl = 'https://stage-auth.roofwallrug.com/'; 
  //cognitoAuthUrl = 'https://stage-roofwallrug.auth.ap-southeast-1.amazoncognito.com/';
  cognitoClientId = '2rt2kg6ujh72fngvfmbav1deq9'; 
  cognitoRedirectUri = 'http://localhost:3000/sign-in';
  gtm = "GTM-W7T9GGG";
  //gtm = "GTM-T3BTS6N";
} else if (env == "stage") {
  apiUrl = "https://stage-api.roofwallrug.com/v1/"
  cognitoAuthUrl = 'https://stage-auth.roofwallrug.com/'; 
  //cognitoAuthUrl = 'https://stage-roofwallrug.auth.ap-southeast-1.amazoncognito.com/'; 
  cognitoClientId = '2rt2kg6ujh72fngvfmbav1deq9';
  cognitoRedirectUri = "https://stage.roofwallrug.com/sign-in";
  gtm = "GTM-W7T9GGG";
} else if (env == "prod") {
  apiUrl = "https://api.roofwallrug.com/v1/"
  cognitoAuthUrl = 'https://auth.roofwallrug.com/';
  cognitoClientId = '5erkhsi7s01dk8ic2b9jf903qa';
  cognitoRedirectUri = "https://roofwallrug.com/sign-in";
  gtm = "GTM-T3BTS6N";
}

export default {
  STAGE_ENV: env,
  API_URL: apiUrl,
  //API2_URL:"https://api2.roofwallrug.com/",
  API2_URL:"https://stage-api.roofwallrug.com/ext/v1/",
  
  // email+openid+phone+profile
  AWS_COGNITO_CLIENT_ID: cognitoClientId,
  AWS_COGNITO_SIGNIN_URL: `${cognitoAuthUrl}login?client_id=${cognitoClientId}&response_type=code&scope=openid&redirect_uri=${cognitoRedirectUri}`,
  RECAPTCHA_SITE_KEY: '6LcJ03QfAAAAAFgohZbjPDol_Y0_SGxMH5i9tfOA',
  // FACEBOOK_APP_ID: "392059468709755",

  GTM: gtm,
};
