import { callApi } from '@/services/WebApi';

const register = (formValues) => {

};

const login = (email, password) => {
  const body = { email, password };
  return callApi('POST', 'account/login', body);
};

const signInAuthCode = (authCode) => {
  const body = { code: authCode };
  return callApi('POST', 'account/sign-in-auth-code', body);
};
const signInToken = (access_token, id_token) => {
  const body = { access_token, id_token };
  return callApi('POST', 'account/sign-in-token', body);
};

const logout = () =>
// remove user from local storage to log user out
// localStorage.removeItem('user');
  callApi('get', 'account/logout');

//const checkAuth = () => callApi('GET', 'account/check-auth');

const resumeLogin = () =>
  // console.log("resumeLogin ");
  callApi('GET', 'account/resume-login');

const refreshToken = () =>
  // console.log("refreshToken ");
  callApi('GET', 'account/refresh-token');

export const accountService = {
  register,
  login,
  signInAuthCode,
  signInToken,
  logout,
  //checkAuth,
  resumeLogin,
  refreshToken,
};
