<template>
  <div class="ribbon left" v-if="stageEnv != 'prod'">{{stageEnv}}</div>


  <ConfirmDialog group="positionDialog"></ConfirmDialog>

  <Dialog v-model:visible="rLoginPrompt" position="top" :draggable="false" 
    :modal="true" :dismissableMask="true" class="login-prompt" >
    <template #header>
      <i class="ico logo-rwr-3 logo"></i>
    </template>
    Please login or regiser to proceed.
    <template #footer>
      <Button label="Login / Register" icon="pi pi-sign-in" class="p-button link" @click="onClickLogin"/>
    </template>
  </Dialog>


  <Dialog v-model:visible="rWelcomePrompt" position="top" :draggable="false" 
    :modal="true" :dismissableMask="true" class="welcome-prompt" >
    <template #header>
      <i class="ico logo-rwr-3 logo"></i>
    </template>
    RoofWallRug (RWR) is a free-to-use listing portal for Singapore real estates. 
    Create your account by logging in and start posting your <a href="/account/listing" @click.prevent="onClickGoToListing">listing</a> now.
    <template #footer>
      <Button label="Continue" class="p-button-text" @click="hideWelcomePrompt"/>
    </template>
  </Dialog>



  <teleport to="head title">{{ cTitle }}</teleport>

  <div :class="['wrapper', cIsMobile?'mobile':'']">
  <!-- {{store.state.site.mobile}} -->
    <component :is="cSiteLayout" />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, toRaw, toRef, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import env from "@/helpers/Environment";
//import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmDialog from "@/components/CustomConfirmDialog.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import _ from "lodash";
import { useRouter, useRoute } from 'vue-router'
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";

const router = useRouter()
const stageEnv = env.STAGE_ENV;
const store = useStore();
const cTitle = computed(() => toRaw(store.state.site.title));
const cSiteLayout = computed(() => toRaw(store.state.site.layoutView));
const cIsMobile = computed(() => store.state.site.mobile.isMobile);
const rLoginPrompt = toRef(store.state.site, "loginPrompt");
const rWelcomePrompt = ref(false);

const debounceResizeWindow = _.debounce(() => {
      store.dispatch('site/checkIsMobile');
    }, 100);
window.addEventListener("resize", debounceResizeWindow);
debounceResizeWindow();

onBeforeMount(async () => {
  // loynote: set title to empty so teleport will not append to it.
  document.title = "";
});

onMounted(()=>{
  const hadShownWelcome = store.state.site.hadShownWelcome;
  //console.log("hadShowWelcome", hadShownWelcome);
  if (!hadShownWelcome) {
    rWelcomePrompt.value = true;
  }
})

const onClickLogin = () => {
  window.location = env.AWS_COGNITO_SIGNIN_URL;
};

 const onClickGoToListing = (e) => {
  hideWelcomePrompt();
  router.push("/account/listing");
 }
 const hideWelcomePrompt = () => {
  rWelcomePrompt.value = false;
  Cookies.set(Constant.COOKIE_HAD_SHOWN_WELCOME, 'true');
 }


</script>

<style lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
}
</style>

<style>
@import "https://cdn.jsdelivr.net/npm/@splidejs/splide@3.6.9/dist/css/splide.min.css";
@import "https://unpkg.com/primevue@^3/resources/themes/saga-blue/theme.css";
@import "https://unpkg.com/primevue@^3/resources/primevue.min.css";
@import "https://unpkg.com/primeflex@2.0.0/primeflex.min.css";
@import "https://unpkg.com/primeicons/primeicons.css";
</style>

<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/listing-common.scss";
@import "@/styles/map.scss";
@import "@/styles/map-listing.scss";
@import "@/styles/map-listing-card.scss";
@import "@/styles/map-listing-detail.scss";
@import "@/styles/account-listing.scss";
@import "@/styles/account-message.scss";
@import "@/styles/account-profile.scss";
@import "@/styles/mobile.scss";
</style>

