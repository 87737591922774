import { ref, watch, toRaw } from 'vue';
import _ from 'lodash';
import { messageService } from '@/services/service.message';
import { listingService } from '@/services/service.listing';

import router from '@/router';
import Utils from '@/helpers/Utils';
import { prepareDropDown, addDisableOptions } from '@/models/common';
import { customJoi as Joi, customJoi, prepareJoiErrorArr } from '@/models/customJoi';
import { showMessage } from '@/components/Notification';

const obj = {
  loading: false,
  messages: [],
};

export const message = {
  namespaced: true,
  state: obj,
  getters: {
    messageCount: (state) => state.messages.length,
  },
  actions: {
    get({ dispatch, commit }) {
      // latest listing from user
      return messageService.getUserMessage()
        .then((res) => {
          const { data } = res;
          commit('getMessage', data);
          return data;
        })
        .catch((err) => {
          console.log('getMessageFailure: ', err);
          return null;
        });
    },
    getUserListingMessage({ dispatch, commit, rootGetters }, listingId) {
      const isLoggedIn = rootGetters['account/isLoggedIn'];
      if (!isLoggedIn) {
        dispatch('site/promptLogin', true, { root: true });
        throw "not logged in";
      }
      return messageService.getUserListingMessage(listingId)
      .then((res) => {
        const { data } = res;
        return data;
      })
      .catch((err) => {
        return null;
      });
    },   
    async post({ dispatch, commit }, {listingId, msg}) {
      await Utils.delay(1000);
      
      return messageService.postMessage(listingId, msg)
        .then((res) => {
          const { data } = res;
          showMessage("Message sent successfully.");
          commit('postMessage', data);
          return data;
        })
        .catch((err) => {
          let msg = "Unexpected error, please try again later."
          if (err.status == 422) {
            msg = err.data[0].message;
          }
          showMessage(msg, "danger");
          commit('postMessageFailure', err.data);
          return false;
        });
    },
    async read({ dispatch, commit, state }, {enquirerUserId, listingId}) {
     
      return messageService.readMessage(enquirerUserId, listingId)
        .then((res) => {
          const { data } = res;
          //showMessage("Message read successfully.");
          //commit('replyMessage', data);
          dispatch("updateRow", data);
          return data;
        })
        .catch((err) => {
          let msg = "Unexpected error, please try again later."
          if (err.status == 422) {
            msg = err.data[0].message;
          }
          showMessage(msg, "danger");
          commit('getMessageFailure', err.data);
          return false;
        });
    },    
    async reply({ dispatch, commit, state }, {enquirerUserId, listingId, msg}) {
      await Utils.delay(1000);
      
      return messageService.replyMessage(enquirerUserId, listingId, msg)
        .then((res) => {
          const { data } = res;
          showMessage("Message sent successfully.");
          //commit('replyMessage', data);
          dispatch("updateRow", data);
          return data;
        })
        .catch((err) => {
          let msg = "Unexpected error, please try again later."
          if (err.status == 422) {
            msg = err.data[0].message;
          }
          showMessage(msg, "danger");
          commit('postMessageFailure', err.data);
          return false;
        });
    },
    updateRow({ dispatch, commit, state }, data) {
      const index = _.findIndex(state.messages, {enquirerUserId:data.enquirerUserId, listingId: data.listingId});
      state.messages.splice(index, 1, data);
      commit('getMessage', state.messages);
    }


  },
  mutations: {
    getMessage(state, data) {
      state.messages = data;
    },
    getMessageFailure(state, err) {
    },
    readMessage(state, data) {
    },
    replyMessage(state, data) {
    }, 
    postMessage(state, data) {
    },     
    postMessageFailure(state, err) {
    },
    
  },
};
