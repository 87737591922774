import moment from 'moment';

const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]';
const lowerKeys = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((o) => lowerKeys(o));
  }
  return Object.keys(obj).reduce((prev, curr) => {
    prev[curr.toLowerCase()] = lowerKeys(obj[curr]);
    return prev;
  }, {});
};

// place functions independent of project here
export default {
  truncate: (str, max) => {
    if (str) {
      return str.length > max ? `${str.substr(0, max - 1)}...` : str;
    }
    return '';
  },
  timestamp: () => Math.round(new Date().getTime() / 1000),
  formatTimestamp: (ts) => {
    const m = moment.unix(ts);
    return m.format('MMMM Do YYYY, h:mm:ss a');
  },
  round: (value, decimals) => {
    decimals = typeof decimals !== 'undefined' ? decimals : 15;
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },
  isset: (obj) => (typeof obj !== 'undefined'),
  isEmail: (email) => {
    //const regex = /^(([^<>()\[]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
    //return true;
  },
  getCookie: (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
  },
  isEmpty: (obj) => Object.keys(obj).length === 0,
  sleep: (ms) => new Promise((res) => setTimeout(res, ms)),
  getEstTimeFormat: (sec) => {
    // console.log(sec)
    let h = Math.floor(sec / (60 * 60));
    const m = `${Math.ceil(sec / 60 % 60)} min`;
    h = h > 0 ? `${h} hr` : '';
    const str = `${h} ${m}`;
    return str;
  },
  formatEstDistance: (d) => {
    d = parseFloat(d);
    if (d > 1000) {
      d /= 1000;
      let s = `${d.toFixed(1)}`;
      if (s.slice(-1) == '0') { // 1.0 = 1
        s = d.toFixed(0);
      }
      return `${s} km`;
    }
    return `${d.toFixed(0)} m`;
  },
  formatNumCommas: (x) => {
    if (!x) { return '0'; }
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    const n = parseFloat(x);
    return n.toLocaleString('en-US');
  },
  formatCurrency: (x) => {
    if (!x) { return '$'; }
    // return "S$ " + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    const n = parseFloat(x);
    return `$${n.toLocaleString('en-US', { maximumFractionDigits: 2 })}`;
  },
  formatShortHandNumber: (value) => {
    if (value >= 1000000) { value = `${value / 1000000}m`; } else if (value >= 1000) { value = `${value / 1000}k`; }
    return value;
  },
  formatTitleCase: (str) => {
    if (!str) return '';
    return str.toLowerCase().split(' ').map((word) => {
      if (word) { return word.replace(word[0], word[0].toUpperCase()); }
      return '';
    }).join(' ');
  },
  formatShortYearMonth: (str) => {
    let s = str.toLowerCase();
    const longName = ['years', 'year', 'months', 'month'];
    const shortName = ['yr', 'yr', 'mth', 'mth'];
    for (let i = 0; i < longName.length; i++) {
      const find = longName[i];
      const replace = shortName[i];
      // console.log("formatShortYearMonth", find);
      const regex = new RegExp(`\\b${find}\\b`, 'g');
      s = s.replace(regex, replace);
      // console.log("formatShortYearMonth str", str);
    }
    return s;
  },
  formatReadableMonthYear: (str) => {
    // 2022-01 to Jan 2022
    const parts = str.split('-');
    const d = new Date(parts[0], parts[1] - 1, 1);
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.toLocaleString('default', { year: 'numeric' });
    // console.log(d);
    return `${month} ${year}`;
  },
  formatReadableMonthYear2: (str) => {
    // 1121 to Nov 2021
    let s = str+"";
    let m = s.substring(0,2);
    let y = s.substring(2, 4);
    if (parseInt(y) < 30) {
      y = 20 + y;
    } else {
      y = 19 + y;
    }
    const d = new Date(y, m - 1, 1);
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.toLocaleString('default', { year: 'numeric' });
    //console.log(s, m, y, "|", d);
    return `${month} ${year}`;
  },
  formatDateDetail: (str) => {
    var d = new Date(parseInt(str) * 1000);
    //13/2/2022, 2:38:45 AM 
    const day = d.toLocaleString('default', { day: '2-digit' });
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.toLocaleString('default', { year: '2-digit' });
    const t = d.toLocaleString('default', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${day} ${month} ${year}, ${t}`;
  },
  formatDateShort: (str) => {
    var d = new Date(parseInt(str) * 1000);
    const day = d.toLocaleString('default', { day: '2-digit' });
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.toLocaleString('default', { year: '2-digit' });    
    return `${day} ${month} ${year}`;
  },  
  formatTimeFromNow: (timestamp) => {
    const str = moment.unix(timestamp).fromNow();
    return str;
  },
  isArray: (obj) => Object.prototype.toString.call(obj) === '[object Array]',
  last: (arr) => arr[arr.length - 1],
  recursivelyLowercaseJSONKeys: (obj) => lowerKeys(obj),
  distance: (lat1, lon1, lat2, lon2, unit) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const radlon1 = (Math.PI * lon1) / 180;
    const radlon2 = (Math.PI * lon2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') { dist *= 1.609344; }
    if (unit == 'N') { dist *= 0.8684; }
    return dist;
  },
  delay: (ms) => new Promise((res) => setTimeout(res, ms)),
  isMobile: () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  },
  getParameterByName:(name, url) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
};
