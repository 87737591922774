import { ref, watch, toRaw } from 'vue';
import _ from 'lodash';
import { listingService } from '@/services/service.listing';
import { thirdPartyService } from '@/services/service.thirdParty';
import router from '@/router';
import Utils from '@/helpers/Utils';
import * as Listing from '@/models/model.listing';
import { prepareDropDown } from '@/models/common';

const obj = {
  loading: false,
  listingDetail: null,
  // propertyInformation: [],
  // saleTransaction: [],
  // rentalTransaction: [],
  // nearbyAmenities: [],
};

export const listingDetail = {
  namespaced: true,
  state: obj,
  getters: {
    isLoading: (state) => state.isLoading,
    listingDetail: (state) => state.listingDetail,
  },
  actions: {
    getListingDetail({ dispatch, commit, state }, id) {
      if (!id) {
        commit('updateListingDetail', null);
        return null;
      }
      // console.log("updateListingDetail", id);
      return listingService.getListing(id)
        .then(async(res) => {
          //await Utils.sleep(500);
          const { data } = res;
          commit('updateListingDetail', data);

          dispatch('getNearbyAmenities', data);

          if (data.propertyType == "hdb") {
            dispatch('getHdbPropertyInformation', data);
            dispatch('getHdbResaleTransaction', data);
            dispatch('getHdbRentalTransaction', data);
          } else {
            dispatch('getPrivateResidentialInformation', data);
          }

          return data;
        })
        .catch((err) => {
          console.log('updateListingDetailFailure: ', err);
          if (err.status == '404') {
            const r = _.pick(err, ['status', 'statusText']);
            const o = { ...r, msg: err.data[0].message };
            commit('updateListingDetailFailure', o);
          }
          return null;
        });
    },
    async getNearbyAmenities({ dispatch, commit, state }, listing) {
      const res = await thirdPartyService.mrt(listing.lat, listing.lng);
      res.data.map((e) => {
        e.latlng = `${e.lat},${e.lon}`;
        e.lng = e.lon;
        e.name = Utils.formatTitleCase(e.name);
        e.distance = Utils.distance(listing.lat, listing.lng, e.lat, e.lng);
        e.type = 'mrt';
      });
      const res2 = await thirdPartyService.supermarket(listing.lat, listing.lng);
      const supermarkets = res2.data.SrchResults;
      supermarkets.shift();
      supermarkets.map((e) => {
        e.lat = e.LatLng.split(',')[0];
        e.lng = e.LatLng.split(',')[1];
        e.distance = Utils.distance(listing.lat, listing.lng, e.lat, e.lng);
        e.type = 'supermarket';
      });
      const arr = Utils.recursivelyLowercaseJSONKeys([...res.data, ...supermarkets]);
      // console.log("amenities???", arr)
      commit('updateNearbyAmenities', arr);
    },
    async getHdbPropertyInformation({ dispatch, commit, state }, p) {
      try {
        const res = await thirdPartyService.hdbPropertyInformation(p.blkNo, p.streetName);
        // console.log("hdbPropertyInformation",res.data);
        let arr = res.data.result.records;
        if (arr) {
          arr = mapHdbPropertyInformation(arr);
          return commit('updateHdbPropertyInformation', arr);
        }
      } catch (e) {}
      commit('updateHdbPropertyInformation', null);
    },
    async getHdbResaleTransaction({ dispatch, commit, state }, p) {
      try {
        const res = await thirdPartyService.hdbResaleTransaction(p.blkNo, p.streetName);
        // console.log("getHdbResaleTransaction",res.data);
        const arr = res.data.result.records;
        if (arr) {
          const u = _.uniqWith(arr, (arrVal, othVal) => {
            // data from gov come as duplicates, need to unique by
            if (arrVal.resale_price == othVal.resale_price && arrVal.month == othVal.month && arrVal.floor_area_sqm == othVal.floor_area_sqm) {
              return true;
            }
            return false;
          });
          const s = _.orderBy(u, ['month'], ['desc']);
          return commit('updateHdbResaleTransaction', s);
        }
      } catch (e) {}
      commit('updateHdbResaleTransaction', null);
    },
    async getHdbRentalTransaction({ dispatch, commit, state }, p) {
      try {
        const res = await thirdPartyService.hdbRentalTransaction(p.blkNo, p.streetName);
        // console.log("getHdbRentalTransaction",res.data);
        const arr = res.data.result.records;
        if (arr) {
          const s = _.orderBy(arr, ['rental_approval_date'], ['desc']);
          // console.log("getHdbRentalTransaction",s);
          return commit('updateHdbRentalTransaction', arr);
        }
      } catch (e) {}
      commit('updateHdbRentalTransaction', null);
    },
    async getPrivateResidentialInformation({ dispatch, commit, state }, p) {
      try {
        const res = await thirdPartyService.privateResidentialInformation(p.postcode);
        let arr = res.data;
        console.log("getPrivateResidentialInformation",arr);
        if (arr) {
          return commit('updatePrivateResidentialInformation', arr);
        }
      } catch (e) {}
      commit('updatePrivateResidentialInformation', null);
    },    
  },
  mutations: {
    updateListingDetail(state, data) {
      state.listingDetail = data;
    },
    updateListingDetailFailure(state, err) { },
    updateNearbyAmenities(state, arr) {
      //state.nearbyAmenities = arr;
    },
    updateHdbPropertyInformation(state, arr) {
      // loynote: datasg gives double row and cannot sort by month desc
      //state.propertyInformation = arr;
    },
    updateHdbResaleTransaction(state, arr) {
      // loynote: datasg gives double row and cannot sort by month desc
      //state.saleTransaction = arr;
    },
    updateHdbRentalTransaction(state, arr) {
      //state.rentalTransaction = arr;
    },
    updatePrivateResidentialInformation(state, arr) {
      //console.log("updatePrivateResidentialInformation", arr);
      //state.hdbRentalTransaction = arr;
    },
    
  },
};

const mapHdbPropertyInformation = (arr) => {
  // console.log("$$$$$$$$", arr);
  if (arr) {
    let temp = arr[0];

    temp = _.omit(temp, 'bldg_contract_town', 'multistorey_carpark', 'residential', 'commercial',
      'precinct_pavilion', 'miscellaneous', 'market_hawker', '_id');
    temp = _.pickBy(temp, (value, key, object) =>
    // console.log(".........", key, value);
      value != '0');
    // sort by key
    temp = Object.keys(temp).sort().reduce((obj, key) => { obj[key] = temp[key]; return obj; }, {});

    const remaining = 99 - (new Date().getFullYear() - parseInt(temp.year_completed));

    let obj = null
    if (temp.blk_no) {
      obj = {
        Block: temp.blk_no,
        Street: temp.street,
        'Year Completed': temp.year_completed,
        'Lease Remaining': `~${remaining} yrs`,
        'Total Dwelling Units': temp.total_dwelling_units,
        'Highest Floor Level': temp.max_floor_lvl,
      };    

      let str = '';
      for (const [key, value] of Object.entries(temp)) {
        if (key.indexOf('multigen') != -1) {
          str += 'Multigen, ';
        } else if (key.indexOf('multigen') != -1) {
          str += 'Studio, ';
        } else if (key.indexOf('1room') != -1) {
          str += '1rm, ';
        } else if (key.indexOf('2room') != -1) {
          str += '2rm, ';
        } else if (key.indexOf('3room') != -1) {
          str += '3rm, ';
        } else if (key.indexOf('4room') != -1) {
          str += '4rm, ';
        } else if (key.indexOf('5room') != -1) {
          str += '5rm, ';
        } else if (key.indexOf('other_room') != -1) {
          str += 'Other, ';
        }
      }
      str = str.substring(0, str.length - 2);
      obj['Available Flat Type'] = str;
    }

    // console.log("#########", obj);
    return obj;
  }

  return null;
};
