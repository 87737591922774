<template>
  <footer :class="['footer', pLayout]">
    <div class="content">
      <div>© 2022 RoofWallRug. <span class="rights">All rights reserved.</span> v{{version}} {{stageEnv}}</div>
      <div class="tnc">
        <router-link to="/about-us" custom v-slot="{ navigate }">
          <a href="/about-us" @click="navigate">About Us</a>
        </router-link>
        <a href="/feedback" target="_blank">Feedback</a>
        <a href="/terms" target="_blank">Terms of use</a>
        <a href="/privacy" target="_blank">Privacy</a>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { onMounted, onBeforeMount, computed, ref } from "vue";
import env from "@/helpers/Environment";

const props = defineProps({
  pLayout: { default: "layout-main" },
});
const version = __APP_VERSION__;
const stageEnv = env.STAGE_ENV == "prod" ? "" : env.STAGE_ENV;

</script>

<style lang="scss">
.footer {
  //position:absolute;
  bottom: 0;
  left: 0;
  height: $footer-secondary-height;
  width: 100%;
  padding: 4px 4px;
  font-size: 0.65em;
  color: $grey-2;
  border-top: 1px solid $grey-5;
  overflow: hidden;
  .content {
    display: flex;
    justify-content: space-between;
    .rights { white-space: nowrap;  }
    .tnc a {
      padding: 0 10px 0 0;
      white-space: nowrap;
    }
  }
}
.layout-main {
  position: absolute;
}
.layout-simple {
  margin-top: 40px;
  position: relative;
}
</style>
