import { callApi } from '@/services/WebApi';

//const getAllUser = async () => callApi('GET', 'user');
const searchUser = async (keyword) => callApi('GET', `user/search/${keyword}`);
const addUser = async (obj) => callApi('POST', 'user', obj);

const editUser = async (obj) => {
  console.log('editUser', obj);
  return callApi('PATCH', 'user/me', obj);
};
const updateMobileRequest = async (obj) => {
  console.log('updateMobileRequest', obj);
  return callApi('POST', 'user/mobile', obj);
};

const verifyMobile = async (obj) => {
  console.log('verifyMobile', obj);
  return callApi('POST', 'user/mobile/verify', obj);
};


const deleteUser = async (userId) =>
  // console.log("deleteProduct", obj);
  callApi('DELETE', `user/${userId}`);
const getFavouriteListing = async () => callApi('GET', 'user/favourite-listing');
const updateFavouriteListing = async (lisitngId) => callApi('PATCH', `user/favourite-listing/${lisitngId}`);

export const userService = {
  //getAllUser,
  searchUser,
  addUser,
  editUser,
  updateMobileRequest,
  verifyMobile,
  deleteUser,
  getFavouriteListing,
  updateFavouriteListing,
};
