import { createStore, createLogger } from 'vuex';

import { account } from './module.account';
import { site } from './module.site';
import { map } from './module.map';
import { listing } from './module.listing';
import { listingDetail } from './module.listingDetail';
import { myListing } from './module.myListing';
import { profile } from './module.profile';
import { message } from './module.message';
import { admin } from './module.admin';

const logger = createLogger({
  actionFilter(action, state) {
    const arr = ['map/onMouseEnterCard', 'map/onMouseLeaveCard', 'map/onMouseEnterDistrict', 'map/onMouseLeaveDistrict',
      'site/setTitle', 'site/setMetadata', 'site/setLayout',
      'listingDetail/getNearbyAmenities', 'listingDetail/getHdbPropertyInformation', 'listingDetail/getHdbResaleTransaction',

    ];
    const isFound = arr.includes(action.type);
    return !isFound;
  },
  filter(mutation, stateBefore, stateAfter) {
    const arr = ['map/addDistrictCountMarkers', 'site/updateTitle', 'site/updateMetadata', 'site/updateLayout',
      'map/addListingMarkers'];
    const isFound = arr.includes(mutation.type);
    return !isFound;
  },
  logActions: false,
  logMutations: false,
});

export default createStore({
  modules: {
    account,
    site,
    map,
    listing,
    listingDetail,
    myListing,
    profile,
    message,
    admin,
  },
  plugins: [logger],

});
