import Utils from '@/helpers/Utils';
import Splide from '@splidejs/splide';
import Constant from './Constant';
import _ from "lodash";

const generateMarker = (id, offerType = 'rental') => {
  //console.log("generateMarker, offerType", offerType);
  const selectedColor = Constant.COLOR_MARKER_PINK;
  let color = selectedColor;
  if (offerType == 'sale') {
    color = Constant.COLOR_MARKER_BLUE;
  } else if (offerType == 'rental') {
    color = Constant.COLOR_MARKER_GREEN;    
  }
  // console.log("generateMarker", id);
  const str = `_${id}`;
  const marker = `
  <div class="listing-marker-holder" id="listing-marker-holder${str}">
      <div class="marker-icon">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <g>      
          <circle cx="100" cy="100" r="100" fill="${selectedColor}" opacity="0" />
          <circle cx="100" cy="100" r="25" fill="${selectedColor}" opacity="1" stroke="white" stroke-width="10" /> 

          <circle id="circle1${str}" cx="100" cy="100" r="40" fill="${color}" opacity="0.5">
            <animate id="anim_icon_over_01${str}" attributeName="r" from="40" to="100" dur="0.02s" begin="indefinite" fill="freeze" />
            <animate id="anim_icon_out_01${str}" attributeName="r" from="100" to="40" dur="0.01s" begin="indefinite" fill="freeze" />
          </circle>
          <circle id="circle2${str}" cx="100" cy="100" r="40" fill="${color}"  stroke="white" stroke-width="10"> 
            <animate attributeName="r" from="40" to="25" dur="0.12s" begin="anim_icon_over_01${str}.begin" fill="freeze" />
            <animate attributeName="r" from="30" to="40" dur="0.11s" begin="anim_icon_out_01${str}.begin" fill="freeze" />
          </circle>
          
        </g>
        </svg>
      </div>
      <div class="overlay-holder" id="listing-overlay${str}"></div>
      <div class="hotspot"></div>
  </div>
  `;
  const myIcon = L.divIcon({
    className: `my-div-icon my-div-icon${str}`, html: marker, iconSize: [10, 10], iconAnchor: [5, 5],
  });
  return myIcon;
};

const generateAmenityMarker = () => {
  // console.log("generateMarker", id);
  const marker = `
      <div class="marker-icon-amenity">
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 264.018 264.018" style="enable-background:new 0 0 264.018 264.018;" xml:space="preserve">
<g>
<path d="M132.009,0c-42.66,0-77.366,34.706-77.366,77.366c0,11.634,2.52,22.815,7.488,33.24c0.1,0.223,0.205,0.442,0.317,0.661
l58.454,113.179c2.146,4.154,6.431,6.764,11.106,6.764c4.676,0,8.961-2.609,11.106-6.764l58.438-113.148
c0.101-0.195,0.195-0.392,0.285-0.591c5.001-10.455,7.536-21.67,7.536-33.341C209.375,34.706,174.669,0,132.009,0z
M132.009,117.861c-22.329,0-40.495-18.166-40.495-40.495c0-22.328,18.166-40.494,40.495-40.494s40.495,18.166,40.495,40.494
C172.504,99.695,154.338,117.861,132.009,117.861z" fill="#f62681"/>
</g>
</svg>
</div>
  `;
  // <circle cx="100" cy="100" r="100" fill="#7BBD42" opacity="0.5"/>
  const myIcon = L.divIcon({
    className: 'my-amenity-div-icon', html: marker, iconSize: [20, 20], iconAnchor: [10, 20],
  });
  return myIcon;
};

const generateAmenityInfoMarker = (res) => {
  // console.log("generateAmenityInfoMarker", res)
  let d = res.route_summary.total_distance;
  let t = res.route_summary.total_time;
  t = Utils.getEstTimeFormat(t);
  d = Utils.formatEstDistance(d);
  const marker = `
      <div class="marker-icon-amenity-info nowrap">
      <i class='ico walk'></i>
      <span>${t} (${d})</span>
      </div>
  `;
  // <circle cx="100" cy="100" r="100" fill="#7BBD42" opacity="0.5"/>
  const myIcon = L.divIcon({
    className: 'my-amenity-info-div-icon', html: marker, iconSize: [10, 10], iconAnchor: [5, 5],
  });
  return myIcon;
};

const generateListingOverlay = (listings, id, pos = 'top') => {
  const l = _.find(listings, (o) => o.properties.listingId == id);
  // console.log("generateListingOverlay", l)
  return generateListingOverlay2(l.properties, id);
};

const generateListingOverlay2 = (listing, id, pos = 'top') => {
  const l = listing;
  // console.log("generateListingOverlay2", id, l)

  const emptyImage = Constant.LISTING_DEFAULT_IMAGE;
  let photoHtml = '';
  l.photos.forEach((p) => {
    photoHtml += `<li class="splide__slide"><img src="${p.baseUrl}thumbnail/${p.path}"></li>`;
    //photoHtml += `<li class="splide__slide"><div class="slide-image" style="background-image: url(${p.baseUrl}thumbnail/${p.path})">wtf</div></li>`;
  });
  if (photoHtml == '') { photoHtml = `<img src="${emptyImage}">`; }

  let price = 'S$';
  if (l.price) {
    price = Utils.formatCurrency(l.price);
  }

  const link = formSeoListingUrl(l)

  const str = `_${id}`;

  let priceSideInfo = `<span class="psf"><i class="ico calculator-grey"></i>${Utils.formatCurrency(l.psf)}psf</span>`;
  if (l.offerType != "sale") {
    const txt = l.rentalType == "whole" ? "whole unit" : "partial / room";
    priceSideInfo = `<span class="rental-type">${txt}</span>`;
  }

  let building = l.building;
  if (building && building.toUpperCase() == "NIL") {
    building = "";
  }
  
  const html = `
  <div class="overlay ${pos}">
    <div class="triangle"></div>
    <div class="content">    
      <div id="map-image-slider${str}" class="splide listing-image-slider">
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev"><i class="ico chevron-left"></i></button>
          <button class="splide__arrow splide__arrow--next"><i class="ico chevron-right"></i></button>
        </div>
        <div class="splide__track">
          <ul class="splide__list">${photoHtml}</ul>
        </div>
        <button class="p-button p-component p-button-rounded detail-btn" type="button">
          <i class="pi pi-search detail"></i>
        </button>
      </div>
      <div class="listing-overview">
        <ul>
          <li class="price-holder">
            <div class="header1">
              ${price}
            </div>
            ${priceSideInfo}

          </li>
          <li>
            <i class="ico bed"></i>${l.bedroom}bd
            <i class="ico bath"></i>${l.bathroom}ba
            <i class="ico ruler"></i>${l.floorArea}sqft
          </li>
          <li class="address-holder">${l.blkNo} ${l.streetName}</li>
          <li class="building-holder">${building} S(${l.postcode})</li>
          <li class="property-type-holder">${Utils.formatTitleCase(`${l.propertyType} ${l.propertySubType}`)}</li>
        </ul>
      </div>
      <div class="listing-footer">
        <span>LISTING BY ${l.user.name.toUpperCase()}</span>
        <a href="${link}" target="_blank"><i class="pi pi-external-link"></i></a>
      </div>
      
    </div>            
     
  </div>
  `;
  // console.log("html",html);

  return html;
};
// <i class="ico detail"></i>
// <div><a href="/listing/${id}">link</a></div>

const mapSupermarketName = (name) => {
  const arr1 = ['COLD STORAGE SINGAPORE (1983) PTE LTD', 'NTUC FAIRPRICE CO-OPERATIVE LTD', 'SHENG SIONG SUPERMARKET PTE LTD', 'U STARS PTE. LTD.', 'HAO MART PTE. LTD.', 'ISETAN (SINGAPORE) LIMITED?', 'MUSTAFA HOLDINGS PTE LTD'];
  const arr2 = ['Cold Storage', 'NTUC Fairprice', 'Sheng Siong Supermarket', 'U Stars Supermarket', 'HAO Mart', 'Isetan', 'Mustafa'];
  for (let i = 0; i < arr1.length; i++) {
    const n = arr1[i];
    if (name == n) {
      return arr2[i];
    }
  }

  return Utils.formatTitleCase(name);
};

// loynote: put in lambda - datasg-resale-hdb-price
/*
const mapDataSgShortStreetName = (street) => {
  let str = street.toLowerCase();
  const longName = ["saint","street","drive","road","avenue","crescent","central","north","south","park","place","bukit","commonwealth","close","jalan","lorong","terrace","market","gardens","heights","industrial","estate","tanjong"]
  const shortName = ["st.","st","dr","rd","ave","cres","ctrl","nth","sth","pk","pl","bt","c'wealth","cl","jln","lor","ter","mkt","gdns","hts","ind","est","tg"]
  for (let i=0;i<longName.length;i++) {
    const find = longName[i];
    const replace = shortName[i];
    console.log("mapDataSgShortStreetName", find);
    var regex = new RegExp('\\b' + find + '\\b', "g");
    str = str.replace(regex, replace);
    console.log("mapDataSgShortStreetName str", str);
  }
  return str.toUpperCase();
}
*/

const mouseenterFeature = async (e, id, listings) => {
  // const map = document.getElementById("map-view-id");
  // const mapPos = map.getBoundingClientRect();
  // const markerPos = {x: (e.clientX-mapPos.x), y:(e.clientY-mapPos.y)};
  // markerPos.px = markerPos.x/(mapPos.width-mapPos.x);
  // markerPos.py = markerPos.y/(mapPos.height-mapPos.y);

  let pos = 'top';
  // if (markerPos.py < 0.3) {
  //   pos = "top-left";
  //   if (markerPos.px > 0.3) pos = "top";
  //   if (markerPos.px > 0.6) pos = "top-right";
  // } else if (markerPos.py < 0.6) {
  //   pos = "center-left";
  //   if (markerPos.px > 0.3) pos = "center";
  //   if (markerPos.px > 0.6) pos = "center-right";
  // } else {
  //   pos = "bottom-left";
  //   if (markerPos.px > 0.3) pos = "bottom";
  //   if (markerPos.px > 0.6) pos = "bottom-right";
  // }

  pos = 'bottom';
  // console.log(id)

  // console.log("pos", pos);

  const str = `_${id}`;
  document.getElementById(`anim_icon_over_01${str}`).beginElement();
  const html = generateListingOverlay(listings, id, pos);
  document.getElementById(`listing-overlay${str}`).innerHTML = html;

  const slider = new Splide(`#map-image-slider${str}`, { rewind: true }).mount();
  // document.getElementById("#map-image-slider"+str).onclick = (e) =>{ slider.go(">");}
};
const mouseleaveFeature = (e, id) => {
  const str = `_${id}`;
  document.getElementById(`listing-overlay${str}`).innerHTML = '';
  document.getElementById(`anim_icon_out_01${str}`).beginElement();
};

const formSeoDistrictUrl = (filerData, district) => {
  const f = filerData;
  //console.log("formSeoDistrictUrl", f);
  const str = `/${f.offerType}/${f.marketType}/${f.propertyType}/district/${district}`;
  return str;
};

const formSeoListingUrl = (listingDetail) => {
  const ld = listingDetail;
  //console.log("formSeoListingUrl", ld);
  const str = `/${ld.offerType}/${ld.marketType}/${ld.propertyType}/${ld.slug}/listing/${ld.listingId}`;
  //console.log("formSeoListingUrl", str)
  return str;
};

export default {
  generateMarker,
  generateAmenityMarker,
  generateAmenityInfoMarker,
  generateListingOverlay,
  generateListingOverlay2,
  mapSupermarketName,
  // mapDataSgShortStreetName,
  mouseenterFeature,
  mouseleaveFeature,

  formSeoListingUrl,
  formSeoDistrictUrl,
};
