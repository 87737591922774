import { Message, Notification, Loadingbar } from 'equal-vue';

const showMessage = (txt = 'hello', type = 'success') => {
  Message({ text: txt, type });
  // Notification({type:type, title:title, text: txt, placement:'bottom-left'});
};

const showLoading = (show = true) => {
  if (show) {
    Loadingbar.start();
  } else {
    Loadingbar.finish();
  }
};

export {
  showMessage,
  showLoading,
};
