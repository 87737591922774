import { userService } from '@/services/service.user';
import { listingService } from '@/services/service.listing';
import { showMessage } from '@/components/Notification';
import Utils from '@/helpers/Utils';
import router from '@/router';
import _ from 'lodash';
import ShareFunc from "@/helpers/ShareFunc";

const obj = { favouriteListingListingIds: [], favouriteListing: [] };
const defaultData = _.cloneDeep(obj);

//user profile
export const profile = {
  namespaced: true,
  state: obj,
  getters: {
    favouriteListingListingIds: (state) => // array of listing id strings
      state.favouriteListingListingIds,
    favouriteListing: (state) => // array of listings detail
      state.favouriteListing
    ,
  },
  actions: {
    async updateProfile ({ dispatch, commit }, formData) {
      await Utils.delay(1000);

      return userService.editUser(formData)
      .then(async (res) => {
        showMessage("Profile update successfully");
        dispatch("account/refreshToken", true, {root: true});
        console.log("updateProfile", res);
        return res;
      })
      .catch((err) => {
        console.log("err", err);
        showMessage("Profile update error", "danger");
        return false;
      })
    },
    async updateMobileRequest ({ dispatch, commit }, {formData, rData}) {
      await Utils.delay(1000);

      return userService.updateMobileRequest(formData)
      .then(async (res) => {
        showMessage("OTP sent to mobile");
        return res;
      })
      .catch((err) => {
        //console.log("err", err);
        rData.errArr = err.data;
        showMessage("OTP send error", "danger");
        return false;
      })
    },
    async verifyMobileOtp ({ dispatch, commit }, {formData, rData}) {
      await Utils.delay(1000);
      
      return userService.verifyMobile(formData)
      .then(async (res) => {
        showMessage("Mobile update successfully");
        dispatch("account/refreshToken", true, {root: true});
        return res;
      })
      .catch((err) => {
        //console.log("err", err);
        rData.errArr = err.data;
        //showMessage("Mobile update error", "danger");
        return false;
      })
    },
    getFavouriteListing({ dispatch, commit }) {
      return userService.getFavouriteListing()
        .then((res) => {
          const { data } = res;
          commit('updateFavouriteListing', { listingIds: data, listingId: null });
          return data.listing;
        })
        .catch((err) => {
          //console.log('updateFavouriteListingFailure:', err);
          // commit('updateFavouriteListingFailure', err);
          showMessage("Error getting favourites.", "danger");
          return null;
        });
    },
    updateFavouriteListing({ dispatch, commit, rootGetters }, listingId) {
      const isLoggedIn = rootGetters['account/isLoggedIn'];
      if (!isLoggedIn) {
        dispatch('site/promptLogin', true, { root: true });
        return;
      }
      
      //ShareFunc.track("favourite-update", "profile", listingId);
      return userService.updateFavouriteListing(listingId)
        .then((res) => {
          const { data } = res;
          console.log("favourite listing", data);
          commit('updateFavouriteListing', { listingIds: data, listingId });
          return data;
        })
        .catch((err) => {
          //console.log('updateFavouriteListingFailure: ', err);
          showMessage("Error updating favourites.", "danger");
          //commit('updateFavouriteListingFailure', err);
          return null;
        });
    },
    getFavouriteListingData({dispatch, commit, state, getters}) {
      if (state.favouriteListingListingIds.length <= 0) {
        commit('updateFavouriteListingData', []);
        return;
      }
      return listingService.getListingBatch(state.favouriteListingListingIds)
        .then((res) => {
          const { data } = res;
          console.log('data', data);
          commit('updateFavouriteListingData', data);
          return data.listing;
        })
        .catch((err) => {
          //console.log('updateFavouriteListingDataFailure: ', err);
          showMessage("Error getting favourite listings.", "danger");
          // commit('updateFavouriteListingFailure', err);
          return null;
        });
    },
  },
  mutations: {
    updateFavouriteListing(state, { listingIds, listingId }) {
      state.favouriteListingListingIds = listingIds;
      if (listingId) {
        const add = listingIds.includes(listingId);
        const msg = add ? 'Add to favourites.' : 'Remove from favourites.';
        const color = add ? 'success' : 'danger';
        showMessage(msg, color);

        if (add) {
          ShareFunc.track("favourite-add", "profile", "listingId", listingId);
        } else {
          ShareFunc.track("favourite-remove", "profile", "listingId", listingId);
        }
      }
    },
    setFavouriteListingList(state, list) {
      //loynote: set list from user favourite listing when login
      //console.log("setList", list)
      state.favouriteListingListingIds = list;

    },
    updateFavouriteListingData(state, listing) {
      state.favouriteListing = listing;
    },

  },

};
