<template>
  <Navbar />
  <main>
    <div :class="['map-view-container']">
      <div class="split-view-holder">
        <MapView ref="rMapView">
          <div id="map-district-town">
            <div class="content"></div>
            <button id="close-map-district-town" class="close-btn"></button>
          </div>
        </MapView>

        <div class="list-view-main-holder">
          <DataError :pErrorObj="rDataError">
            <router-view v-slot="{ Component }">
              <keep-alive>
                <component :is="Component" />
              </keep-alive>
            </router-view>
          </DataError>
          <br /><br /><br />
          <Footer />
        </div>
      </div>
    </div>

    <div id="overlay-template"></div>
  </main>
</template>

<script setup>
import { onBeforeUnmount, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Navbar from "@/views/_layout/Navbar.vue";
import Footer from "@/views/_layout/Footer.vue";
import MapView from "@/views/Map.Leaflet.vue";
import DataError from "@/views/_layout/DataError.vue";
import router from "@/router";
import Constant from "@/helpers/Constant";


const store = useStore();
const rDataError = ref(null);

onMounted(async () => {
  // await nextTick();
  store.dispatch('site/setTitle', Constant.SITE_TITLE);
  const metadata = [{ name: "description", content: Constant.SITE_DESC }];
  store.dispatch("site/setMetadata", metadata);  
});

// loynote: subscribe for hydration
const unsub1 = store.subscribe(async (mutation, state) => {
  if (mutation.type == "map/init") {
    const route = router.currentRoute.value;

    if (route.name == "map") {
      // const f = store.state.listing.filterData;
      // f.offerType = route.params.pOfferType;
      // f.marketType = route.params.pMarketType;
      store.dispatch("map/hydrateMap", { level: "overview", id: null });
      store.dispatch("listing/clearDistrict");            
      store.dispatch("listing/searchFilterData");
    } else if (route.name == "district-listing") {
      store.dispatch("map/hydrateMap", {level: "district", id: route.params.pDistrict,});
    } else if (route.name == "listing-detail") {
      const ld = await store.dispatch("listingDetail/getListingDetail", route.params.pListingId);
      if (ld) {
        store.dispatch("map/hydrateMap", {level: "listing",id: route.params.pListingId,listing: ld,});
      }
    } else if (route.name == "admin-listing-detail") {
      const ld = await store.dispatch("admin/getListingDetail", route.params.pListingId);
      if (ld) {
        store.dispatch("map/hydrateMap", {level: "listing",id: route.params.pListingId,listing: ld,});
      }
    }
    // hydrate mylisting edit form
    else if (route.name == "") {
    }
    // router.doRoute(route);
    // console.log("map/init");
    unsub1();
  }
});

const unsub2 = store.subscribe((mutation, state) => {
  if (mutation.type == "listing/updateListingFailure") {
    const err = mutation.payload;
    rDataError.value = err;
  } else if (mutation.type == "listingDetail/updateListingDetailFailure") {
    const err = mutation.payload;
    rDataError.value = err;
  } else if (mutation.type == "site/routeError") {
    const err = mutation.payload;
    rDataError.value = err;
  }
});




onBeforeUnmount(() => {
  unsub1();
  unsub2();
});


</script>


<style lang="scss">
//@import "@/styles/listing-common.scss";
//@import "@/styles/map.scss";
</style>
