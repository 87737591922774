// MY LISTING

import { ref, watch, toRaw } from 'vue';
import _ from 'lodash';
import { listingService } from '@/services/service.listing';
import router from '@/router';
import Utils from '@/helpers/Utils';
import * as Listing from '@/models/model.listing';
import { prepareDropDown, addDisableOptions } from '@/models/common';
import { customJoi as Joi, customJoi, prepareJoiErrorArr } from '@/models/customJoi';
import { showMessage } from '@/components/Notification';
import ShareFunc from "@/helpers/ShareFunc";

const formData = {
  // photos: [ { "url": null, "data": null }],
  photos: [],
  blkNo: '',
  streetName: '',
  postcode: '',
  building: '',
  propertyType: '',
  marketType: 'residential',
  // marketTypes: prepareDropDown(Listing.marketTypes),
  marketTypes: addDisableOptions(['commerical', 'industrial'], prepareDropDown(Listing.marketTypes)),
  offerType: 'sale',
  offerTypes: prepareDropDown(Listing.offerTypes),
  rentalType: '',
  rentalTypes: prepareDropDown(Listing.rentalTypes.slice(1)),
  // propertyTypes: addDisableOptions(['condo', 'landed', 'other'],prepareDropDown(Listing.propertyTypes)),
  propertyTypes: prepareDropDown(Listing.propertyTypes),
  propertySubType: [],
  propertySubTypesHdb: prepareDropDown(Listing.hdbTypes.slice(1)),
  propertySubTypesCondo: prepareDropDown(Listing.condoTypes.slice(1)),
  propertySubTypesLanded: prepareDropDown(Listing.landedTypes.slice(1)),
  propertySubTypesOther: prepareDropDown(Listing.otherTypes.slice(1)),
  price: null,
  floorArea: null,
  landSize: null,
  buildYear: null,
  bedroom: '',
  bedrooms: prepareDropDown(Listing.bedrooms.slice(1), Listing.bedroomValues.slice(1)),
  bathroom: '',
  bathrooms: prepareDropDown(Listing.bathrooms.slice(1), Listing.bathroomValues.slice(1)),
  tenure: '',
  tenuresHdb: addDisableOptions(['999 years', 'freehold'], prepareDropDown(Listing.tenures.slice(1))),
  tenures: prepareDropDown(Listing.tenures.slice(1)),
  furnishing: '',
  furnishings: prepareDropDown(Listing.furnishings.slice(1)),
  floorLevel: '',
  floorLevels: prepareDropDown(Listing.floorLevels.slice(1)),
  floorLevelsLanded: addDisableOptions(['low', 'mid', 'high', 'super high', 'penthouse'], prepareDropDown(Listing.floorLevels.slice(1))),
  description: '',
  visibilitySetting: 'public',
  visibilitySettings: prepareDropDown(Listing.visibilitySettings),
  mobileDisplaySetting: 'show',
  mobileDisplaySettings: prepareDropDown(Listing.mobileDisplaySettings),
};
const defaultFormData = _.cloneDeep(formData);

// console.log("defaultFormData", defaultFormData);

const obj = {
  loading: false,
  formData,
  formJoiError: null,

  listing: [],
  // listingDetail:null,
};

export const myListing = {
  namespaced: true,
  state: obj,
  getters: {
    isLoading: (state) => state.isLoading,
    formData: (state) => state.formData,
    formJoiError: (state) => state.formJoiError,
    listingCount: (state) => state.listing.length,
  },
  actions: {
    resetFormData({ dispatch, commit }) {
      return commit('resetFormData');
    },
    resetFormError({ dispatch, commit }) {
      return commit('resetFormError');
    },
    async validateForm({ dispatch, commit }, {formData, formType = "draft"}) {
      let j = null;
      if (formType == "draft") {
        j = customJoi.object(Listing.draft());
      } else if (formType == "submit") {
        j = customJoi.object(Listing.submit());
      } else {
        //setting
        j = customJoi.object(Listing.setting());
      }
      
      const { value, error } = j.validate(formData);
      //console.log(formData, value, error);
      //console.log(JSON.stringify(error));

      if (error) {
        const msg = error.details[0].message;
        showMessage(msg, "danger");
        commit('validateFailure', prepareJoiErrorArr(error));
        return false;
      }
      commit('validateSuccess');
      return value;
    },
    //need to check if photo is at least length 1
    async postSubmit({ dispatch, commit }, { listingId, formData }) {
      console.log("postSubmit",listingId, formData)
      const value = await dispatch("validateForm", {formData, formType:"submit"});
      if (!value) return false;

      //await Utils.delay(1000);
      return listingService.postSubmit(listingId, value)
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch((err) => {
          let msg = "Unexpected error, please try again later."
          if (err.status == 422) {
            msg = err.data[0].message;
          }
          console.log('post my listing', err);
          showMessage(msg, "danger");
          commit('updateListingFailure', err.data);
          return false;
        });
    },
    async postDraft({ dispatch, commit }, formData) {
      const value = await dispatch("validateForm", {formData, isDraft:true});
      if (!value) return false;      
      //await Utils.delay(1000);

      return listingService.postDraft(value)
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch((err) => {
          let msg = "Unexpected error, please try again later."
          if (err.status == 422) {
            msg = err.data[0].message;
          }
          console.log('post my listing', err);
          showMessage(msg, "danger");
          commit('updateListingFailure', err.data);
          return false;
        });
    },    
    async patchDraft({ dispatch, commit }, { listingId, formData }) {
      const value = await dispatch("validateForm", {formData, formType:"draft"});
      if (!value) return false;

      return listingService.patchDraft(listingId, value)
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch((err) => {
          console.log('patch my listing', err);
          commit('updateListingFailure', err.data);
          return false;
        });
    },
    async patchSetting({ dispatch, commit }, { listingId, formData }) {
      const value = await dispatch("validateForm", {formData, formType:"setting"});
      if (!value) return false;

      return listingService.patchSetting(listingId, value)
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch((err) => {
          console.log('patch my listing', err);
          commit('updateListingFailure', err.data);
          return false;
        });
    },    
    async reSubmit({ dispatch, commit }, listingId) {
      return listingService.reSubmit(listingId)
      .then((res) => {
        const { data } = res;
        return data;
      })
      .catch((err) => {
        let msg = "Unexpected error, please try again later."
        console.log('submit', err);
        showMessage(msg, "danger");
        commit('updateListingFailure', err.data);
        return false;
      });
    },
    async cancelSubmit({ dispatch, commit }, listingId) {
      return listingService.cancelSubmit(listingId)
      .then((res) => {
        const { data } = res;
        return data;
      })
      .catch((err) => {
        let msg = "Unexpected error, please try again later."
        console.log('cancelSubmit', err);
        showMessage(msg, "danger");
        commit('updateListingFailure', err.data);
        return false;
      });
    },
    delete({ dispatch, commit }, listingId) {
      // console.log("delete", listingId);
      return listingService.deleteListing(listingId)
        .then((res) => {
          const { data } = res;
          // return data;
          return true;
        })
        .catch((err) => {
          console.log('patch my listing', err);
          commit('validateFailure', err.data);
          return null;
        });
    },
    selectPostcode({ dispatch, commit }, data) {
    },
    userId({ dispatch, commit }, userId) {
      // latest listing from user
      return listingService.getUserListing(userId)
        .then((res) => {
          const { data } = res;
          commit('updateListing', data);
          return data;
        })
        .catch((err) => {
          console.log('updateListingFailure: ', err);
          // commit('updateListingFailure', err);
          return null;
        });
    },
    getEditListingDetail({ dispatch, commit, state }, id) {
      if (!id) {
        commit('updateEditListingDetail', null);
        return null;
      }
      //ShareFunc.track("my-listing-edit-detail", "my-listing", id);
      return listingService.getOwnerListing(id)
        .then((res) => {
          const { data } = res;
          commit('resetFormError');
          commit('updateEditFormData', data);
          return data;
        })
        .catch((err) => {
          console.log('getEditListingDetail Failure: ', err);
          // commit('updateListingFailure', err);
          return null;
        });
    },
    getListingHistory({ dispatch, commit, state }, id) {
      if (!id) {
        commit('updateListingHistory', null);
        return null;
      }
      ShareFunc.track("my-listing-get-history", "my-listing", "listingId", id);
      return listingService.getListingHistory(id)
        .then((res) => {
          const { data } = res;
          commit('updateListingHistory', data);
          return data;
        })
        .catch((err) => {
          console.log('getListingHistory Failure: ', err);
          // commit('updateListingHistoryFailure', err);
          return null;
        });
    },


  },
  mutations: {
    resetFormData(state) {
      state.formData = {};
      Object.assign(state.formData, defaultFormData);
      state.formData.photos = []; // loynote: buggy
      // console.log("resetFormData", state.formData, defaultFormData)
      return state.formData;
    },
    resetFormError(state) {
      state.formJoiError = null;
      return state.formJoiError;
    },
    validateFailure(state, err) {
      // console.log("validateFailure", state.formJoiError);
      // state.formJoiError = prepareJoiErrorArr(err);
      state.formJoiError = err;
    },
    validateSuccess(state, err) {
      state.formJoiError = null;
    },
    updateListing(state, listing) {
      // const s = _.sortBy(listing, "lng")
      // console.log("updateListing", s);
      state.listing = listing;
    },
    updateListingFailure(state, err) {
      //state.formJoiError = err;
    },
    updateEditFormData(state, listing) {
      // loynote: need to form up url for photo preview
      for (const item of listing.photos) {
        item.url = item.baseUrl + item.path;
      }
      // loynote: bd & br need to be in string values
      listing.bedroom += '';
      listing.bathroom += '';
      Object.assign(state.formData, defaultFormData);
      Object.assign(state.formData, listing);
    },
    updateListingHistory(state, history) {
    },
    updateListingHistoryFailure(state, err) {
    },

  },
};
