import { toRaw } from 'vue';
import _ from 'lodash';
import { listingService } from '@/services/service.listing';
import * as Listing from '@/models/model.listing';
import { prepareDropDown } from '@/models/common';

const obj = { 
  foo:"bar",
  loading: false,
};

export const admin = {
  namespaced: true,
  state: obj,
  getters: {
    isLoading: (state) => state.loading,
  },
  actions: {
    getAdminListingByStatus({
      dispatch, commit, state, getters, rootState,
    }, listingStatus) {
      state.loading = true;
      
      return listingService.getAdminListingByStatus(listingStatus)
        .then((res) => {
          const { data } = res;
          commit('updateListing', data);
          state.loading = false;
          return data;
        })
        .catch((err) => {
          console.log('updateListingFailure: ', err);
          if (err.status == '422') {
            const r = _.pick(err, ['status', 'statusText']);
            const o = { ...r, msg: err.data[0].message };
            commit('updateListingFailure', o);
          }
          state.loading = false;
          return null;
        });
    },
    getListingDetail({ dispatch, commit, state }, id) {
      if (!id) {
        commit('listingDetail/updateListingDetail', null, { root: true });
        return null;
      }
      // console.log("updateListingDetail", id);
      return listingService.getAdminListing(id)
        .then(async(res) => {
          //await Utils.sleep(500);
          const { data } = res;
          commit('listingDetail/updateListingDetail', data, { root: true });
          dispatch('listingDetail/getNearbyAmenities', data, { root: true });

          if (data.propertyType == "hdb") {
            dispatch('listingDetail/getHdbPropertyInformation', data, { root: true })
            dispatch('listingDetail/getHdbResaleTransaction', data, { root: true })
            dispatch('listingDetail/getHdbRentalTransaction', data, { root: true })
          } else {
            dispatch('listingDetail/getPrivateResidentialInformation', data, { root: true })
          }

          return data;
        })
        .catch((err) => {
          console.log('updateListingDetailFailure: ', err);
          if (err.status == '404') {
            const r = _.pick(err, ['status', 'statusText']);
            const o = { ...r, msg: err.data[0].message };
            commit('listingDetail/updateListingDetailFailure', o, { root: true })
          }
          return null;
        });
    },
    getListingHistory({ dispatch, commit, state }, id) {
      if (!id) {
        commit('myListing/updateListingHistory', null, { root: true });
        return null;
      }
      return listingService.getAdminListingHistory(id)
        .then((res) => {
          const { data } = res;
          commit('myListing/updateListingHistory', data, { root: true });          
          return data;
        })
        .catch((err) => {
          console.log('getListingHistory Failure: ', err);
          //commit('myListing/updateListingHistoryFailure', err, { root: true });
          return null;
        });
    },    
    async action ({ dispatch, commit }, {action, listingId, note=""}) {
      console.log("action", action, listingId, note);
      const data = {note:note};
      return listingService.postAdminAction(action, listingId, data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return false;
      })
    },    
    async scrape ({ dispatch, commit }, {source, url, offerType}) {
      console.log("submitScrape", source, url, offerType);
      const data = {source:source, url:url, offerType:offerType};
      return listingService.postAdminScrape(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return false;
      })
    },       
  },
  mutations: {
    updateListing(state, listing) {
      state.listing = listing;
    },
    updateListingFailure(state, err) { },
  },
};

