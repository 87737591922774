import axios from 'axios';
import env from '@/helpers/Environment';
import { showMessage } from '@/components/Notification';
import store from '@/stores';

const STATUS_OK = 200;
const STATUS_BAD_REQUEST = 400;
const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
const STATUS_UNPROCESSABLE_ENTITY = 422;
const STATUS_TOO_MANY_REQUEST = 429;
const MISSING_API_CREDENTIALS = 'missing_api_credentials';
const INVALID_API_CREDENTIALS = 'invalid_api_credentials';

const cancelToken = axios.CancelToken;
let cancelTokenSource = cancelToken.source();

const cancelPrevAxiosRequest = () => {
  console.log('cancelPrevAxiosRequest');
  cancelTokenSource.cancel();
  cancelTokenSource = new cancelToken.source();
};

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
// axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  async (res) => {
    const { url } = res.config;
    const path = url.substring(env.API_URL.length, url.length);
    // console.log("response: ", path, res.data);

    return res;
  },
  async (err) => {
    const res = err.response;
    console.log('intercept error', err, res);

    if (res) {
      //console.log("wtf!!!")
      const { url } = res.config;
      const path = url.substring(env.API_URL.length, url.length);
      // console.log("path", path)
      if (res.status == STATUS_FORBIDDEN) {
        // console.log("STATUS_FORBIDDEN", res);
        // showMessage('Forbidden request: [' + path + ']', 'warning');
        // return Promise.reject(res);
        // TODO: do global alert & log out
        // return Promise.reject("FORBIDDEN");
      } else if (res.status == STATUS_UNAUTHORIZED) {
        const publicPaths = ['refresh-token'];
        const found = publicPaths.find((v) => path.includes(v));
        if (found) {
          return Promise.reject(res);
        }
        const r = await store.dispatch('account/refreshToken');
        if (r) {
          const jwt = store.getters['account/token'];
          const originalRequest = err.config;
          originalRequest.headers.Authorization = jwt; // update expired jwt
          console.log('...auto try with refresh token...');
          return axios(originalRequest);
        }
      } else if (res.status == STATUS_UNPROCESSABLE_ENTITY) {

      } else if (res.status == STATUS_TOO_MANY_REQUEST) {
        showMessage('Too many request. Please try again later.', 'warning');
      }
      return Promise.reject(res);
    }
    // console.log("CORS OR NETWORK ERROR");
    // TODO: do global alert for unexpected error
    const errorObj = { status: 503, statusText: 'Service Unavailable', msg: 'CORS or Network Error' };
    return Promise.reject(errorObj);
  },
);

const callApi = async (method, endpoint, data, cancelable = true) => {
  const url = env.API_URL + endpoint;
  const jsonData = JSON.stringify(data);

  const jwt = store.getters['account/token'];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwt,
  };
  return callApiUrl(method, url, jsonData, headers, cancelable, true);
};

const callApi2 = async (method, endpoint, data, cancelable = true) => {
  const url = env.API2_URL + endpoint;
  const jsonData = JSON.stringify(data);
  const headers = {
    'Content-Type': 'application/json',
  };
  return callApiUrl(method, url, jsonData, headers, cancelable, false);
};

const callApiUrl = async (method, url, data, headers = {}, cancelable = true, withCredentials = true) => {
  // console.log("url", url);

  const config = {
    method,
    url,
    withCredentials,
  };
  if (headers) { config.headers = headers; }
  if (data) { config.data = data; }

  if (cancelable) {
    config.cancelToken = cancelTokenSource.token;
  }

  // console.log("callApi config", config);

  return axios(config);
};
// get error message
// transfer logic to use/userError
// [{field: "email", message: " "}, {field: "password", message: "Incorrect credentials."}]

export {
  callApi, callApi2, callApiUrl, cancelPrevAxiosRequest,
};
