import _ from 'lodash';
import { customJoi as Joi } from '@/models/customJoi';

export const prices = ['Any', '$200k', '$300k', '$400k', '$500k', '$600k', '$700k', '$800k', '$900k', '$1m', '$1.2m', '$1.4m', '$1.6m', '$1.8m', '$2m', '$2.5m', '$3m'];
const [, ...p] = prices;
export const pricesMin = ['No Min', ...p];
export const pricesMax = ['No Max', ...p];

export const psfs = ['Any', '200', '400', '500', '600', '700', '800', '900', '1000', '1200', '1300', '1400', '1500', '1600', '1700', '1900', '2000', '2100', '2200', '2300', '2400', '2500', '2600', '2700', '2800'];
const [, ...ps] = psfs;
export const psfsMin = ['No Min', ...ps];
export const psfsMax = ['No Max', ...ps];

export const priceValues = [null, '200000', '300000', '400000', '500000', '600000', '700000', '800000', '900000', '1000000', '1200000', '1400000', '1600000', '1800000', '2000000', '2500000', '3000000'];
export const bedrooms = ['Any', '1', '2', '3', '4', '5+'];
export const bedroomValues = ['Any', '1', '2', '3', '4', '5'];
export const bathrooms = ['Any', '1', '2', '3', '4', '5+'];
export const bathroomValues = ['Any', '1', '2', '3', '4', '5'];

export const floorAreas = ['Any', '750', '1000', '1250', '1500', '1750', '2000', '2250', '2500', '2750'];
const [, ...f] = floorAreas;
export const floorAreasMin = ['No Min', '500', ...f];
export const floorAreasMax = ['No Max', ...f, '3000', '4000', '5000'];

export const landSizes = ['Any', '2000', '3500', '5000', '6500', '8000'];
const [, ...l] = landSizes;
export const landSizesMin = ['No Min', '1500', ...l];
export const landSizesMax = ['No Max', ...l, '10000'];

export const buildYears = ['Any', '1900', '1920', '1940', '1950', '1960', '1970', '1980', '1990', '2000', '2005', '2010', '2015', '2016', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'];
const [, ...b] = buildYears;
export const buildYearsMin = ['No Min', ...b];
export const buildYearsMax = ['No Max', ...b];

export const marketTypes = ['residential', 'commerical', 'industrial'];
export const offerTypes = ['sale', 'rental'];
export const rentalTypes = ['any', 'whole', 'partial']; //required when offer type is rental
export const propertyTypes = ['hdb', 'condo', 'landed', 'other'];
export const hdbTypes = ['any', 'studio', '1 room', '2 room', '3 room', '4 room', '5 room', 'jumbo', 'maisonette', 'other'];
export const hdbTypes2 = ['any type', ...hdbTypes.slice(1)];
export const condoTypes = ['any', 'condominium', 'apartment', 'walk up', 'cluster house', 'executive condominium', 'other'];
export const condoTypes2 = ['any type', ...condoTypes.slice(1)];
export const landedTypes = ['any', 'cluster house', 'conservation house', 'corner terrace', 'terrace house', 'detached house', 'semi-detached house', 'bungalow', 'good class bungalow', 'land only', 'shophouse', 'other'];
export const landedTypes2 = ['any type', ...landedTypes.slice(1)];
export const otherTypes = ['any', 'other'];
export const otherTypes2 = ['any type', ...otherTypes.slice(1)];
export const tenures = ['any', '99 years', '999 years', 'freehold'];
export const furnishings = ['any', 'none', 'partial', 'full'];
export const floorLevels = ['any', 'ground', 'low', 'mid', 'high', 'super high', 'penthouse'];
export const currency = ['sgd'];
// export const visibilitySettings = ['public', 'private', 'unlisted'];
export const visibilitySettings = ['public', 'unlisted'];
export const mobileDisplaySettings = ["show", "hide"];

export const listingStatuses = ['draft', 'pending review', 'active', 'expired', 'disabled'];

const photo = Joi.object().keys({
  // key: [Joi.string().required(), Joi.strip()],
  key: Joi.string().required(),
  // folder: Joi.forbidden().default("media/user/"),
}).options({ stripUnknown: true });

const photoEdit = Joi.object().keys({
  key: Joi.when('baseUrl', { is: Joi.exist(), then: Joi.strip(), otherwise: Joi.string().required() }),
  baseUrl: Joi.string(),
  path: Joi.string(),
}).options({ stripUnknown: true });

export const joiSchema = {
  photos: Joi.array().items(photoEdit, Joi.invalid().strip()).default([]),
  // photo: Joi.array().items(photo, Joi.invalid().strip()),
  videoLink: Joi.string().uri().allow(null).allow("").optional(),
  postcode: Joi.string().min(6).max(6).required(),
  estate: Joi.string().max(50).allow(''),
  // district: formDistrict,
  blkNo: Joi.string().max(10).allow(''),
  streetName: Joi.string().max(100).allow(''),
  building: Joi.string().max(100).allow(''),
  title: Joi.string().max(100).allow(''),
  price: Joi.number().integer().min(0).max(1000000000)
    .options({ convert: true })
    .required(),
  floorArea: Joi.number().integer().min(0).max(1000000)
    .options({ convert: true })
    .required(),
  // psf: Joi.forbidden().default((parent, helpers)=>{ return Math.ceil(parent.price/parent.floorArea);}),
  landSize: Joi
    .when('propertyType', {
      is: 'landed',
      then:
      Joi.number().integer().min(0).max(1000000)
        .options({ convert: true })
        .allow(null).allow("").optional(),
    }),
  // marketType: Joi.valid(...marketTypes).default(marketTypes[0]),
  // offerType: Joi.valid(...offerTypes).default(offerTypes[0]),
  marketType: Joi.valid(...marketTypes).required(),
  offerType: Joi.valid(...offerTypes).required(),
  rentalType: Joi.when("offerType", {is:"rental", 
        then:Joi.valid(...rentalTypes).default("whole"),otherwise:Joi.any().strip()}),
  propertyType: Joi.valid(...propertyTypes).required(),
  propertySubType: Joi
    .when('propertyType', { is: 'hdb', then: Joi.valid(...hdbTypes).required() })
    .when('propertyType', { is: 'condo', then: Joi.valid(...condoTypes).required() })
    .when('propertyType', { is: 'landed', then: Joi.valid(...landedTypes).required() })
    .when("propertyType", {is:"other", then:Joi.valid(...otherTypes).default("other")}),
  bedroom: Joi.number().integer().min(0).max(10).options({ convert: true }).required(),
  bathroom: Joi.number().integer().min(0).max(10).options({ convert: true }).required(),
  tenure: Joi.string().lowercase().trim().valid(...tenures).required(),
  buildYear: Joi.number().integer().min(1900).max(2100).options({ convert: true }).allow(null).allow("").optional(),
  furnishing: Joi.string().lowercase().trim().valid(...furnishings).required(),
  floorLevel: Joi.string().lowercase().trim().valid(...floorLevels).required(),
  description: Joi.string().max(4000).allow(''),
  visibilitySetting: Joi.valid(...visibilitySettings).default(visibilitySettings[0]),
  mobileDisplaySetting: Joi.valid(...mobileDisplaySettings).default(mobileDisplaySettings[0]),

  // latLng: formLatLng,
  // lat: Joi.string(),
  // lng: Joi.string(),

};

export const checkOfferMarketProperty = () => {
  const c = _.cloneDeep(joiSchema);
  const o = _.pick(c, ['offerType', 'marketType', 'propertyType']);
  return o;
};


export const draft = () => {
  const o = _.cloneDeep(joiSchema);
  return o;
};


export const submit = () => {
  const o = _.cloneDeep(joiSchema);
  o.photos = Joi.array().items(photoEdit, Joi.invalid().strip()).default([]).min(1);
  // delete o.postcode;
  return o;
};


export const setting = () => {
  const c = _.cloneDeep(joiSchema);
  const o = _.pick(c, ['visibilitySetting', 'mobileDisplaySetting']);
  return o;
};


export const submitScrape = () => {
  const c = _.cloneDeep(joiSchema);
  const o = _.pick(c, ["offerType"]);
  o.source = Joi.string().required();
  o.url = Joi.string().required();    
  return o;
}

