import { accountService } from '@/services/service.account';
import { showMessage } from '@/components/Notification';
import router from '@/router';
import _ from 'lodash';
import ShareFunc from "@/helpers/ShareFunc";


// user data from login api
const objUser = { name: null, email: null };
const obj = { user: objUser, token: null };

const defaultUserData = _.cloneDeep(objUser);

export const account = {
  namespaced: true,
  state: obj,
  getters: {
    isLoggedIn: (state) => state.user && state.user.email != null,
    //isAdmin: (stage) => state.user && state.user.roleAccess.admin,
    token: (state) => state.token, // jwt token
    user: (state) => state.user,
    isMobileVerified: (state) => {
      if ( state.user.mobileStatus && state.user.mobileStatus == "verified" ) {
        return true;
      }
      return false;      
    },
  },
  actions: {
    login({ dispatch, commit }, { email, password, errArr }) {
      return accountService.login(email, password)
        .then((res) => {
          const { data } = res;
          const { redirect } = router.currentRoute.value.query;
          // console.log("accountService.login", data, redirect);

          commit('loginSuccess', { data, redirect });
          commit('profile/setFavouriteListingList', data.user.favouriteListing,  { root: true });
        })
        .catch((err) => {
          errArr.value = err.data;
          console.log('loginFailure: ', err);
          commit('loginFailure', err);
        });
    },
    signInAuthCode({ dispatch, commit }, { authCode }) {
      //ShareFunc.track("account-sign-in-auth-code", "account", "sign-in");
      return accountService.signInAuthCode(authCode)
        .then((res) => {
          const { data } = res;
          const { redirect } = router.currentRoute.value.query;
          //console.log('accountService.signIn', data, redirect);
          commit('loginSuccess', { data, redirect });
          commit('profile/setFavouriteListingList', data.user.favouriteListing,  { root: true });
        })
        .catch((err) => {
          console.log('loginFailure: ', err);
          commit('loginFailure', err);
        });
    },
    // signInToken({ dispatch, commit }, { accessToken, idToken }) {
    //   return accountService.signInToken(accessToken, idToken)
    //     .then((res) => {
    //       const { data } = res;
    //       const { redirect } = router.currentRoute.value.query;
    //       console.log('accountService.signIn', data, redirect);
    //       commit('loginSuccess', { data, redirect });
    //     })
    //     .catch((err) => {
    //       console.log('loginFailure: ', err);
    //       commit('loginFailure', err);
    //     });
    // },
    logout({
      dispatch, commit, state, getters,
    }) {
      //ShareFunc.track("account-logout", "account", "logout");
      accountService.logout()
        .then((res) => {
          console.log('logout', res);
          commit('logoutSuccess');
        }).catch((err) => {
          commit('logoutFailure');
        });
    },
    // checkAuth({ commit }) {
    //   return accountService.checkAuth()
    //     .then((res) => true)
    //     .catch((err) => { commit('logout'); return false; });
    // },
    resumeLogin({
      dispatch, commit, state, getters,
    }) {
      console.log("resumeLogin");
      //ShareFunc.track("account-resume-login", "account", "resume-login");
      return accountService.resumeLogin()
        .then((res) => {
          // console.log("res", res);
          const data = res.data;
          commit('resumeLoginSuccess', data);
          commit('profile/setFavouriteListingList', data.user.favouriteListing,  { root: true });
          return true;
        })
        .catch((err) => {
          console.log(err);
          commit('resumeLoginFailure');
          return false;
        });
    },
    refreshToken({ commit }) {
      // loynote: refresh token via cookie's refreshtoken withCredential = true;
      return accountService.refreshToken()
        .then((res) => {
          const data = res.data;
          commit('refreshTokenSuccess', data);
          commit('profile/setFavouriteListingList', data.user.favouriteListing,  { root: true });
          return true;
        })
        .catch((err) => {
          // console.log("refreshTokenFailure: ", err);
          commit('refreshTokenFailure');
          return false;
        });
    },
  },
  mutations: {
    loginSuccess(state, { data, redirect = '/home' }) {
      // console.log("loginSuccess", data, redirect);
      // { "user": {}, "token": null, "refreshToken": null }
      state.user = data.user;
      state.token = data.token;
      // state.tokenExpiredAt = jwt.decode(data.token).exp;
      showMessage('Login successfully.');
      // router.push(redirect);
      // TODO:: do not store in localstorage in prod
      // cookies in lower case
    },
    loginFailure(state, err) {
      let msg = "Login failure."
      if (err.data && err.data.message) { msg = err.data.message; }
      showMessage(msg, 'danger');
      state.user = null;
    },
    logoutSuccess(state) {
      showMessage('Logout successfully.', 'warning');
      state.user = defaultUserData;
      state.token = null;
      console.log('logoutSuccess', state);
    },
    logoutFailure(state) {
      showMessage('Unable to logout.', 'danger');
      state.user = defaultUserData;
      state.token = null;
      console.log('logoutFailure', state);
    },
    resumeLoginSuccess(state, data) {
      //showMessage('Resume successfully');
      state.user = data;
    },
    resumeLoginFailure(state) {
      // showMessage('Resume failed.', 'danger')
      state.user = defaultUserData;
      state.token = null;
    },
    refreshTokenSuccess(state, data) {
      state.user = data.user;
      state.token = data.token;
      // state.tokenExpiredAt = jwt.decode(data.token).exp;
    },
    refreshTokenFailure(state) {
      // showMessage('Unable to refresh token.', 'danger')
    },
  },

};
