<template>
    <div class="search-input p-inputgroup">
      <!-- <InputText placeholder="Search for MRT, Neighborhood, Postal Code" v-model="rKeyword" /> -->
          <AutoComplete forceSelection v-model="rData.search.keyword" :suggestions="rData.search.suggestions" 
            field="label" key="key"
            @complete="searchLocation"  placeholder="Search for MRT, Town, Estate" 
            @item-select="onSelectItem" class="search-postcode">
                <template #item="slotProps">
                    <div class="auto-complete-primary">{{slotProps.item.label}}</div>
                    <span class="auto-complete-sub">{{slotProps.item.type}}</span>
                </template>
          </AutoComplete>          
      
      <Button icon="pi pi-search" class="p-button-warning" @click="onClickSearch"/>
    </div>
</template>

<script setup>
import { ref, toRef, computed, watch, reactive } from "vue";
import Utils from '@/helpers/Utils';
import MapFunc from '@/helpers/MapFunc';
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import router from '@/router';
import searchDataJson from "@/assets/json/searchData.json";
const searchData = searchDataJson.data;

const props = defineProps({
  pStore: { type: Object, required: true },
});
const rData = reactive({
  search: { keyword: '', suggestions: null },
  selectedItem: null,
});

 const searchLocation = (e) => {
  var res = searchData.filter((row)=> {
    const n = row.label.toLowerCase();
    const q = e.query.toLowerCase();
    //console.log(row, n)
    return n.indexOf(q) == 0; 
    //return true;
  });
  
  //rData.formError = '';
  //console.log('obj.length', res.length, res);
  if (res.length > 0) {
    rData.search.suggestions = res;
  } else {
    res.forEach( r => r.key = r.type+":"+r.label);
    rData.search.suggestions = [{label: 'No results', value: null, key:"123"}];
  }
};    

const onSelectItem = async(e) => {
  //console.log("onSelectItem", e)
  const obj = e.value;
  if (obj.value != null) {
    rData.selectedItem = obj;
    rData.search.keyword = obj.label;
    const sFilterData = props.pStore.state.listing.filterData;
    const str = MapFunc.formSeoDistrictUrl(sFilterData, obj.district);
    router.push(str);
    if (obj.type == "MRT" || obj.type == "LRT") {
      await Utils.sleep(500);
      props.pStore.dispatch("map/flyToLatLng", {lat:obj.lat, lng:obj.lng});        
    }
  } else {
    rData.search.keyword = "";
  }      
};


const onClickSearch = (e) => {
  //console.log("onClickSearch", rData.search.keyword)
  if (rData.search.keyword) {
    onSelectItem({value:rData.selectedItem});
  }
  
}


</script>

<style scoped lang="scss">

</style>


