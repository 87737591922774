import { callApi, callApi2 } from '@/services/WebApi';
import Utils from '@/helpers/Utils';
import MapFunc from '@/helpers/MapFunc';

const postcode = (str) => callApi2('GET', `onemap/postcode?postcode=${str}`);

const mrt = (lat, lng) => callApi2('GET', `onemap/mrt?lat=${lat}&lng=${lng}`);

const supermarket = (lat, lng) => callApi2('GET', `onemap/supermarket?lat=${lat}&lng=${lng}`);

const route = (lat1, lng1, lat2, lng2) => callApi2('GET', `onemap/route?start=${lat1},${lng1}&end=${lat2},${lng2}`);

const hdbPropertyInformation = (block, street) => callApi2('GET', `datasg/hdb-property-information?b=${block}&s=${street}`);

const hdbResaleTransaction = (block, street) => {
  // return callApi2("GET", "datasg/resale-hdb-price?q="+keyword);
  // const s = MapFunc.mapDataSgShortStreetName(street)
  return callApi2('GET', `datasg/hdb-resale-transaction?b=${block}&s=${street}`);
}

const hdbRentalTransaction = (block, street) => {
  // return callApi2("GET", "datasg/resale-hdb-price?q="+keyword);
  // const s = MapFunc.mapDataSgShortStreetName(street)
  return callApi2('GET', `datasg/hdb-rental-transaction?b=${block}&s=${street}`);
// https://data.gov.sg/api/action/datastore_search?resource_id=f1765b54-a209-4718-8d38-a39237f502b3&limit=20&q=855 yishun
}

const privateResidentialInformation = (postcode) => {
  return callApi2('GET', `ura/private-residential-information?postcode=${postcode}`);
}

export const thirdPartyService = {
  postcode,
  mrt,
  supermarket,
  route,
  hdbPropertyInformation,
  hdbResaleTransaction,
  hdbRentalTransaction,
  privateResidentialInformation,
};
