import { toRaw } from 'vue';
import _ from 'lodash';
import { listingService } from '@/services/service.listing';
import * as Listing from '@/models/model.listing';
import { prepareDropDown } from '@/models/common';
import router from '@/router';

const LOCAL_STORAGE_FILTER_KEY = "rwr_filter"

const prepareReverseMinMax = (entry) => {
  let min = null;  let max = null;
  if (entry[0]) {min = entry[0] == "-1" ? null : entry[0];}
  if (entry[1]) {max = entry[1];}
  return {min:min, max:max};
};


const filterData = {
  // _init: false,
  keyword: null,
  district: null,
  marketType: 'residential',
  offerType: 'sale',
  offerTypes: prepareDropDown(Listing.offerTypes),
  rentalType: 'any',
  rentalTypes: prepareDropDown(Listing.rentalTypes),
  propertyType: 'hdb',
  propertyTypes: prepareDropDown(Listing.propertyTypes),
  propertySubType: ['any'],
  propertySubTypes: prepareDropDown(Listing.hdbTypes),
  propertySubTypes2: prepareDropDown(Listing.hdbTypes2, Listing.hdbTypes), // change 'any type'
  price: { min: null, max: null },
  prices: {
    min: prepareDropDown(Listing.pricesMin, Listing.priceValues),
    max: prepareDropDown(Listing.pricesMax, Listing.priceValues),
  },
  psf: { min: null, max: null },
  psfs: {
    min: prepareDropDown(Listing.psfsMin),
    max: prepareDropDown(Listing.psfsMax),
  },
  floorArea: { min: null, max: null },
  floorAreas: {
    min: prepareDropDown(Listing.floorAreasMin),
    max: prepareDropDown(Listing.floorAreasMax),
  },
  landSize: { min: null, max: null },
  landSizes: {
    min: prepareDropDown(Listing.landSizesMin),
    max: prepareDropDown(Listing.landSizesMax),
  },
  buildYear: { min: null, max: null },
  buildYears: {
    min: prepareDropDown(Listing.buildYearsMin).reverse(),
    max: prepareDropDown(Listing.buildYearsMax).reverse(),
  },
  bedroom: ['any'],
  bedrooms: prepareDropDown(Listing.bedrooms, Listing.bedroomValues),
  bathroom: ['any'],
  bathrooms: prepareDropDown(Listing.bathrooms, Listing.bathroomValues),
  tenure: ['any'],
  tenures: prepareDropDown(Listing.tenures),
  furnishing: ['any'],
  furnishings: prepareDropDown(Listing.furnishings),
  floorLevel: ['any'],
  floorLevels: prepareDropDown(Listing.floorLevels),
  // visibilitySetting: 'public',
  // visibilitySettings: prepareDropDown(Listing.visibilitySettings),
};
const defaultfilterData = _.cloneDeep(filterData);

const getLocalStorageFilterData = () => {
  const f1 = _.cloneDeep(filterData);
  const f2 = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTER_KEY));  
  if (f2 && f2.price) { f2.price = prepareReverseMinMax(f2.price) };
  if (f2 && f2.psf) { f2.psf = prepareReverseMinMax(f2.psf) };
  if (f2 && f2.floorArea) { f2.floorArea = prepareReverseMinMax(f2.floorArea) };
  if (f2 && f2.landSize) { f2.landSize = prepareReverseMinMax(f2.landSize) };
  if (f2 && f2.buildYear) { f2.buildYear = prepareReverseMinMax(f2.buildYear) };

  const f3 = Object.assign(f1, f2);
  //console.log("filterData", f3)
  return f3;
}



const obj = {
  loading: false, // loynote: when in loading, don't watch for changes
  listingSelected: null,
  listing: [],
  listingDetail: null,
  watcher: {offerType: "sale"}, // loynote: prevent double loading, put var out of filterData
  filterData: getLocalStorageFilterData(),
  listingDistrictOverview: {},
  // districtSelected:null,
};

export const listing = {
  namespaced: true,
  state: obj,
  getters: {
    isLoading: (state) => state.loading,
    // listingDetail: (state) => state.listingDetail,
    listing: (state) => state.listing,
    listingCount: (state) => state.listing.length,
    filterData: (state) => state.filterData,
    keyword: (state) => state.filterData.keyword,
  },
  actions: {
    updateFilterDataOption({ dispatch, commit }) {
      // loynote: eg. change hdb sub types
      commit('updateFilterDataOption');
    },
    restrictFilterDataValue({ dispatch, commit }, param) {
      // loynote: eg. any, 5 will reset arr
      commit('restrictFilterDataValue', param);
    },
    searchFilterData({
      dispatch, commit, state, getters,
    }) {
      const f = toRaw(state.filterData);
      // console.log("onClickFilter reactiveData",f);
      // const f2 = _.pick(f, ["keyword", "propertyType", "propertySubType", "price", "floorArea", "landSize", "buildYear", "bedroom", "bathroom", "tenure", "furnishing", "floorLevel"]);
      // const f3 = _.omitBy(f2, _.isNil);
      const f2 = {};
      // f2.keyword = f.keyword;
      f2.marketType = f.marketType;
      f2.offerType = f.offerType;
      f2.rentalType = f.rentalType;
      f2.propertyType = f.propertyType;
      f2.propertySubType = f.propertySubType;
      f2.postcode = f.postcode;
      f2.district = f.district;
      f2.price = prepareMinMax(f.price);
      f2.psf = prepareMinMax(f.psf);
      f2.floorArea = prepareMinMax(f.floorArea);
      f2.landSize = prepareMinMax(f.landSize);
      f2.buildYear = prepareMinMax(f.buildYear);
      f2.bedroom = f.bedroom;
      f2.bathroom = f.bathroom;
      f2.tenure = f.tenure;
      f2.furnishing = f.furnishing;
      f2.floorLevel = f.floorLevel;

      if (f2.offerType == "sale") {
        f2.rentalType = "any";
      }

      const f3 = _.pickBy(f2, (v) => v != 'any' && v != '' && v != 'null,null' && !_.isUndefined(v) && !_.isNull(v));

      const {offerType, ...ls} = f3;
      localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, JSON.stringify(ls));

      return dispatch('search', f3);
    },
    searchDistrict({
      dispatch, commit, state, getters,
    }, district) {
      // dispatch('map/setMapViewLevel', "district", { root: true })
      // commit('updateDistrictSelected', district);
      if (district != null) {
        state.filterData.district = district;
        return dispatch('searchFilterData');
      }
    },
    clearDistrict({
      dispatch, commit, state, getters,
    }, district) {
      commit('clearDistrictFilterData');
    },
    resetFilterData({ dispatch, commit }) {
      //dispatch('map/setMapViewLevel', { level: 'overview', id: null }, { root: true });
      router.push("/");
      commit('resetFilterData');
    },
    // loynote: search is called locally in this file only, but is subscribed in other files for "loading" state
    search({
      dispatch, commit, state, getters, rootState,
    }, query) {
      state.loading = true;
      // if (rootState.site.mobile.isMobile) {
      //   dispatch("site/setShowingMobileMap", false, {root:true});
      // }
      

      return listingService.search(query)
        .then((res) => {
          const { data } = res;
          commit('updateListing', data);
          const o = _.countBy(data, 'district');
          commit('updateListingDistrictOverview', o);
          state.loading = false;
          return data;
        })
        .catch((err) => {
          console.log('updateListingFailure: ', err);
          if (err.status == '422') {
            const r = _.pick(err, ['status', 'statusText']);
            const o = { ...r, msg: err.data[0].message };
            commit('updateListingFailure', o);
          }
          state.loading = false;
          return null;
        });
    },
    //get poster number
    getMobile({ dispatch, commit, rootGetters }, listingId) {
      const isLoggedIn = rootGetters['account/isLoggedIn'];
      if (!isLoggedIn) {
        dispatch('site/promptLogin', true, { root: true });
        throw "not logged in";
      }      
      return listingService.getListingMobile(listingId)
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch((err) => {
          console.log('get mobile failure: ', err);
          return null;
        });
    },     
  },
  mutations: {
    updateFilterDataOption(state) {
      state.filterData.propertySubType = [];
      const { propertyType } = state.filterData;
      if (propertyType == 'hdb') {
        state.filterData.propertySubTypes = prepareDropDown(Listing.hdbTypes);
      } else if (propertyType == 'condo') {
        state.filterData.propertySubTypes = prepareDropDown(Listing.condoTypes);
      } else if (propertyType == 'landed') {
        state.filterData.propertySubTypes = prepareDropDown(Listing.landedTypes);
      } else {
        state.filterData.propertySubTypes = prepareDropDown(Listing.otherTypes);
      }
      state.filterData.propertySubType = ['any'];
      
      const arr = _.cloneDeep(state.filterData.propertySubTypes);      
      try { arr[0].name = 'Any Type'; } catch (e) { console.log(e); }
      state.filterData.propertySubTypes2 = arr;
      // console.log("state.filterData.propertySubTypes2", state.filterData.propertySubTypes2);
    },
    restrictFilterDataValue(state, param) {
      //const selectButtonParams = ['bedroom', 'bathroom', 'propertySubType', 'tenure', 'furnishing', 'floorLevel'];
      //console.log('restrictfilterDataValues', state.filterData.bedroom);
      // selectButtonParams.forEach(p => {
      // console.log("onChangeSelectBtn", e, param);
      // const p = "bedroom";
      const p = param;
      const arr = state.filterData[p];
      if (arr.length) {
        const lastSelected = arr[arr.length - 1];
        // console.log("onChangeSelectBtn", lastSelected);
        if (lastSelected == 'any') {
          state.filterData[p] = ['any'];
        } else if (lastSelected == '5') {
          state.filterData[p] = ['5'];
        } else {
          state.filterData[p] = arr.filter((s) => s != 'any' && s != '5');
        }
      } else {
        state.filterData[p] = ['any'];
      }
      // });
    },
    updateListing(state, listing) {
      // const s = _.sortBy(listing, "lng")
      // console.log("updateListing", s);
      state.listing = listing;
    },
    updateListingDistrictOverview(state, overview) {
      // console.log("updateListingDistrictOverview", overview);
      state.listingDistrictOverview = overview;
    },
    updateListingFailure(state, err) { },
    resetFilterData(state) {
      // loynote: should not reset propertyType, propertySubType should show respective
      const ot = state.filterData.offerType;
      const pt = state.filterData.propertyType;      
      Object.assign(state.filterData, defaultfilterData);      
      state.filterData.offerType = ot;
      state.filterData.propertyType = pt;
      const t1 = Listing[pt+"Types"];
      const t2 = Listing[pt+"Types2"];
      state.filterData.propertySubTypes = prepareDropDown(t1),
      state.filterData.propertySubTypes2 = prepareDropDown(t2, t1);      
    },
    clearDistrictFilterData(state) {
      state.filterData.district = null;
    },

  },
};

const prepareMinMax = (entry) => {
  // console.log("prepareMinMax", entry.min, entry.max);
  let min = null; let
    max = null;
  if (entry.min) {
    min = entry.min;
  }
  if (entry.max) {
    max = entry.max;
    if (min == null || min == '') {
      min = -1;
    }
  }
  // console.log("prepareMinMax", min, max);
  if (!min && !max) {
    return null;
  }
  const arr = [min, max];
  // if (min && max) {
  //   arr = arr.sort((a,b)=>{return a-b;});
  // }
  // return arr.filter(Boolean).join(",");
  return arr;
};

