import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import App from './App.vue';
import router from './router';
import store from './stores';
import VueLazyLoad from 'vue3-lazyload'
import 'core-js/features/array/at'; //loynote: polyfill for latlng.at !!!
import env from "@/helpers/Environment";
import { createGtm } from '@gtm-support/vue-gtm';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

import Equal from 'equal-vue';
//import { Message } from 'equal-vue';
import 'equal-vue/dist/style.css'
import Button from 'primevue/button';


const gtm = createGtm({
  id: env.GTM,
  defer: true,
  compatibility: true,
  enabled: true,
  debug: false,
  vueRouter: router,
});



const app = createApp(App)
  .use(store)
  .use(router)
  //.use(Equal)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(VueLazyLoad, {
    loading: 'https://stage-media.roofwallrug.com/media/sys/spinner.svg',
    error: 'https://stage-media.roofwallrug.com/media/sys/error.svg',
  })
  .use(gtm)
  //.component('Button', Button)
  .mount('#app');
