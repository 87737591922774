<template>
  <div class="centered" v-if="pErrorObj">
    <div>
      <div class="code">Error {{pErrorObj.status}}</div>
      <div class="desc">{{pErrorObj.statusText}}</div>
      <div class="msg">{{pErrorObj.msg}}</div>
      <div class="retry">
        <!-- <Button label="Retry?" class="theme" @click.prevent="reloadPage" autofocus /> -->
        <Button label="Home" class="home-btn theme" @click.prevent="goHome" autofocus />
      </div>
    </div>
  </div>
  <slot  v-if="!pErrorObj"/>
</template>

<script setup>
  import { ref } from 'vue';
  import Button from 'primevue/button';

  const props = defineProps({
    pErrorObj: { default: null },
  });

  const reloadPage = () => {
    window.location.reload();
  };

  const goHome = () => {
    window.location = "/";
  }

</script>

<style lang="scss" scoped>
  .centered {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2em;
    .code { font-weight: bold;}
    .desc { font-size:1em;}
    .msg { font-size:0.8em;}

    .home-btn { margin:10px 0; }

  }
</style>
