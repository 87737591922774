import { callApi } from '@/services/WebApi';
import ShareFunc from "@/helpers/ShareFunc";

const getUserMessage = async () => callApi('GET', `message`);

//intiation of message, create row
const postMessage = async (listingId, msg) => {
  ShareFunc.track("message-post", "message", "listingId", listingId);
  const data = {"messages":[{"desc":msg}]};
  return callApi('post', `message/listing/${listingId}`, data)
};
const readMessage = async (enquirerUserId, listingId) => {
  ShareFunc.track("message-read", "message", "enquirerUserId/listingId", enquirerUserId+"/"+listingId);
  return callApi('get', `message/read/${enquirerUserId}/${listingId}`)
};
const replyMessage = async (enquirerUserId, listingId, msg) => {
  ShareFunc.track("message-reply", "message", "enquirerUserId/listingId", enquirerUserId+"/"+listingId);
  const data = {"messages":[{"desc":msg}]};
  return callApi('post', `message/reply/${enquirerUserId}/${listingId}`, data)
};
 
const getUserListingMessage = async (listingId) => {
  return callApi('get', `message/listing/${listingId}`)
}

export const messageService = {
  getUserMessage,
  postMessage,
  readMessage,
  replyMessage,
  getUserListingMessage,
};
