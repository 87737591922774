import { callApi } from '@/services/WebApi';
import ShareFunc from "@/helpers/ShareFunc";


const listing = async () => callApi('GET', 'listing');
const listingOverview = async () => callApi('GET', 'listing/overview');

const convertGeoJson = (listing) => {
  // TODO: Drop feature if latlng or anything is not valid.
  const json = {
    type: 'FeatureCollection',
    features: [],
  };
  listing.forEach((l) => {
    const f = {
      type: 'Feature',
      properties: {
        listingId: l.listingId,
        marketType: l.marketType,
        offerType: l.offerType,
        rentalType: l.rentalType,
        user: l.user,
        photos: l.photos,
        videoLink: l.videoLink,
        propertyType: l.propertyType,
        propertySubType: l.propertySubType,
        postcode: l.postcode,
        district: l.district,
        blkNo: l.blkNo,
        streetName: l.streetName,
        building: l.building,
        title: l.title,
        price: l.price,
        floorArea: l.floorArea,
        psf: l.psf,
        landSize: l.landSize,
        bedroom: `${l.bedroom}`,
        bathroom: `${l.bathroom}`,
        floorLevel: l.floorLevel,
        tenure: l.tenure,
        buildYear: l.buildYear,
        furnishing: l.furnishing,
        description: l.description,
        //visibilitySetting: l.visibilitySetting,
        visibilityStatus: l.visibilityStatus,
        listingStatus: l.listingStatus,
        slug: l.slug,
        createdAt: l.createdAt,
        updatedAt: l.updatedAt,
        listedAt: l.listedAt,
        expiredAt: l.expiredAt,        
      },
    };
    if (l.lat && l.lng) {
      f.geometry = { type: 'Point', coordinates: [parseFloat(l.lng, 15), parseFloat(l.lat, 15)] };
    }
    json.features.push(f);
  });
  // console.log("geojson",json);
  // console.log("listing total:", json.features.length)
  return json;
};

const search = async (params) => {
  let str = '';
  for (const k in params) {
    //console.log("search params", k, params[k]);
    let p = params[k];
    if (Array.isArray(p)) {
      p = params[k].sort();
      str += `&${k}=${p.join(',')}`;
    } else {
      str += `&${k}=${p}`;
    }
  }
  // console.log("listing/search",str)
  ShareFunc.track("listing-search-filter", "listing", "query", str);
  return callApi('GET', `listing/search/${str}`);
};

const getListing = async (listingId) => callApi('GET', `listing/${listingId}`);
const getListingHistory = async (listingId) => callApi('GET', `listing/history/${listingId}`);
const getOwnerListing = async (listingId) => callApi('GET', `listing/draft/${listingId}`);

const getListingBatch = async (arr) => {
  const str = arr.join(',');
  return callApi('GET', `listing/batch/${str}`);
};

const postSubmit = async (listingId, formData) => callApi('POST', `listing/${listingId}`, formData);
const postDraft = async (formData) => callApi('POST', 'listing/draft', formData);
const patchDraft = async (listingId, formData) => callApi('PATCH', `listing/draft/${listingId}`, formData);
const patchSetting = async (listingId, formData) => callApi('PATCH', `listing/setting/${listingId}`, formData);
const cancelSubmit = async (listingId) => callApi('POST', `listing/cancel-submit/${listingId}`);
const reSubmit = async (listingId) => callApi('POST', `listing/re-submit/${listingId}`);

const deleteListing = async (listingId) =>
  // console.log("deleteListing", listingId)
  callApi('DELETE', `listing/${listingId}`);
const getUserListing = async (userId) => callApi('GET', `listing/user/${userId}`);

const getListingMobile = async (listingId) => { 
  //ShareFunc.track("listing-get-mobile", "listing", listingId);
  ShareFunc.track("message-get-mobile", "message", "listingId", listingId);
  return callApi('GET', `listing/mobile/${listingId}`)
};

//admin
const getAdminListingByStatus = async (listingStatus) => {
  return callApi('GET', `admin/listing/status/${listingStatus}`);
};
const getAdminListing = async (listingId) => callApi('GET', `admin/listing/${listingId}`);
const getAdminListingHistory = async (listingId) => callApi('GET', `admin/listing/history/${listingId}`);
const postAdminAction = async (action, listingId, data) => callApi('POST', `admin/listing/${action}/${listingId}`, data);
const postAdminScrape = async (data) => callApi('POST', `admin/listing/scrape`, data);


export const listingService = {
  listing,
  listingOverview,

  search,
  getListing,
  getListingHistory,
  getOwnerListing,  

  postSubmit,
  postDraft,
  patchDraft,
  patchSetting,
  
  //post submit is required becoz photo need to be at least length 1
  reSubmit,
  cancelSubmit,

  getListingBatch,  
  deleteListing,
  getUserListing,
  convertGeoJson,
  getListingMobile,

  getAdminListingByStatus,
  getAdminListing,
  getAdminListingHistory,
  postAdminAction,
  postAdminScrape,
};
