import { ref, watch, toRaw } from 'vue';
import _ from 'lodash';
import router from '@/router';
import Utils from '@/helpers/Utils';
import { thirdPartyService } from '@/services/service.thirdParty';
import ShareFunc from "@/helpers/ShareFunc";

const LOCAL_STORAGE_BASEMAP_KEY = "rwr_basemap"

const mapViewLevelTypes = ['overview', 'district', 'listing'];
const obj = {
  init: false,
  listingMarkers: [],
  districtCountMarkers: [],
  mapViewLevel: mapViewLevelTypes[0],
  basemap: localStorage.getItem(LOCAL_STORAGE_BASEMAP_KEY) || 'Carto',
  selectedDistrictId: null,
  selectedListingId: null,
  nearbyAmenities: [],
  amenityRoute: null,
};

export const map = {
  namespaced: true,
  state: obj,
  getters: {
    isInit: (state) => state.init,

  },
  actions: {
    // replaceState??
    init({ dispatch, commit }) {
      commit('init');
    },
    async hydrateMap({ dispatch, commit, state }, { level, id, listing = null }) {
      if (level == 'overview') {
        commit('updateMapViewLevel', level);
      } else if (level == 'district') {
        commit('updateMapViewLevel', level);
      } else if (level == 'listing') {
        commit('updateMapViewLevel', level);
      }
    },
    setMapViewLevel({ dispatch, commit, state }, { level, id }) {
      //console.log("wtf setMapViewLevel", level, id)
      if (level == 'district') { commit('updateSelectedDistrictId', id); }
      if (level == 'listing') { commit('updateSelectedListingId', id); }
      commit('updateMapViewLevel', level);
    },
    storeBaseMapSetting({ dispatch, commit, state }, basemap) {
      state.basemap = basemap;
      localStorage.setItem(LOCAL_STORAGE_BASEMAP_KEY, basemap);
    },

    /*
        async getNearbyAmenities({ dispatch, commit, state }, listing) {
          const res = await thirdPartyService.mrt(listing.lat, listing.lng);
          res.data.map((e) => {
            e.latlng = e.lat+","+e.lon;
            e.lng = e.lon;
            e.name = Utils.formatTitleCase(e.name);
            e.distance = Utils.distance(listing.lat, listing.lng, e.lat, e.lng)
            e.type = "mrt";
          });
          const res2 = await thirdPartyService.supermarket(listing.lat, listing.lng);
          const supermarkets = res2.data.SrchResults;
          supermarkets.shift();
          supermarkets.map((e) => {
            e.lat = e.LatLng.split(",")[0];
            e.lng = e.LatLng.split(",")[1];
            e.distance = Utils.distance(listing.lat, listing.lng, e.lat, e.lng)
            e.type = "supermarket";
          });
          const arr = Utils.recursivelyLowercaseJSONKeys([...res.data, ...supermarkets]);
          //console.log("amenities???", arr)
          commit('updateNearbyAmenities', arr);
        }, */
    async routeToAmenity({ dispatch, commit, state, rootState }, p) {
      ShareFunc.track("map-route-amenity", "map", "amenity", p.amenityName);

      return thirdPartyService.route(p.lat1, p.lng1, p.lat2, p.lng2)
        .then((res) => {
          const { data } = res;
          commit('updateAmenityRoute', data);
          if (rootState.site.mobile.isMobile) {
            dispatch("site/setShowingMobileMap", true, {root:true});
          }
          return data;
        })
        .catch((err) => {
          commit('updateAmenityRoute', null);
          console.log('updateAmenityRouteFailure: ', err);
        });
    },
    flyToListingMarker({ dispatch, commit, state, rootState }, id) {
      if (rootState.site.mobile.isMobile) {
        dispatch("site/setShowingMobileMap", true, {root:true});
      }
      return true;
    },
    flyToDistrictBounds({ dispatch, commit, state }, district) {
      return true;
    },
    flyToLatLng({ dispatch, commit, state, rootState }, {lat, lng}) {
      if (rootState.site.mobile.isMobile) {
        dispatch("site/setShowingMobileMap", true, {root:true});
      }
      return true;
    },      
    // loynote: when rollover card, marker in map need to indicate
    onMouseEnterCard({ dispatch, commit }, listing) {
      return true;
    },
    onMouseLeaveCard({ dispatch, commit }, listing) {
      return true;
    },
    onMouseEnterDistrict({ dispatch, commit }, district) {
      return true;
    },
    onMouseLeaveDistrict({ dispatch, commit }, district) {
      return true;
    },
    resetAccountMap({ dispatch, commit, state }) {
      return true;
    },
  },
  mutations: {
    init(state) {
      state.init = true;
    },
    updateMapViewLevel(state, l) {
      state.mapViewLevel = l;
    },
    updateSelectedDistrictId(state, id) {
      state.selectedDistrictId = id;
    },
    updateSelectedListingId(state, id) {
      state.selectedListingId = id;
    },
    /*
        updateNearbyAmenities(state, arr) {
          state.nearbyAmenities = arr;
        },
        */
    updateAmenityRoute(state, res) {
      state.amenityRoute = res;
    },

  },
};
