export default {
  SITE_TITLE: "Singapore Real Estate for Sale/Rent | RoofWallRug",
  SITE_DESC: "Singapore property for sale/rent - free-to-use fast listing portal for real estates in Singapore",
  SITE_KEYWORD: "Singapore property, Singapore real estate, property for sale, property for rent, Singapore property listings",

  LISTING_DEFAULT_IMAGE: "https://stage-media.roofwallrug.com/media/sys/gray.jpg",
  STATUS_ENABLED: 'enabled',
  STATUS_DISABLED: 'disabled',

  SINGAPORE_CENTER: [1.36904, 103.81530],
  SINGAPORE_TOP_LEFT: [1.490, 103.550],
  SINGAPORE_BOTTOM_RIGHT: [1.150, 104.115],

  COOKIE_WAS_LOGGED_IN: "was_logged_in",
  COOKIE_HAD_SHOWN_WELCOME: "had_shown_welcome",

  COLOR_MARKER_PINK: '#F62681',
  COLOR_MARKER_BLUE: '#004080',
  COLOR_MARKER_GREEN: '#7BBD42',


};
