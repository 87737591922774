import MainLayout from '@/views/_layout/Main.vue';
import MainBrandingLayout from '@/views/_layout/MainBranding.vue';
import AccountLayout from '@/views/_layout/Account.vue';
import BlankLayout from '@/views/_layout/Blank.vue';
import SimpleLayout from '@/views/_layout/Simple.vue';
import Constant from "@/helpers/Constant";
import Cookies from 'js-cookie'
import Utils from '../helpers/Utils';
import ShareFunc from "@/helpers/ShareFunc";

const obj = {
  init: false,
  title: 'RoofWallRug',
  metadata: [],
  layout: 'blank',
  layoutView: BlankLayout,
  loginPrompt: false,
  wasLoggedIn: Cookies.get(Constant.COOKIE_WAS_LOGGED_IN),
  hadShownWelcome: Cookies.get(Constant.COOKIE_HAD_SHOWN_WELCOME),
  mobile: { isMobile: false, isShowingMap: false},
  currentScreenCategory: null,
};

export const site = {
  namespaced: true,
  state: obj,
  getters: {
    isInit: (state) => state.init,
    getLayout: (state) => state.layoutView,
    wasLoggedIn: (state) => { 
      return Cookies.get(Constant.COOKIE_WAS_LOGGED_IN) == "true"
    },
    hadShownWelcome: (state) => { 
      return Cookies.get(Constant.COOKIE_HAD_SHOWN_WELCOME) == "true"
    },
    isMobile: (state) => state.isMobile,
  },
  actions: {
    init({ dispatch, commit }) {
      commit('init');
    },
    setTitle({ dispatch, commit, state }, title) {
      // console.log("settitle", title);
      commit('updateTitle', title);
    },
    setMetadata({ dispatch, commit, state }, metadata) {
      commit('updateMetadata', metadata);
    },
    setLayout({ dispatch, commit, state }, layout) {
      let v = MainLayout;
      if (layout == 'main') {
        v = MainLayout;
      } else if (layout == 'main-branding') {
        v = MainBrandingLayout
      } else if (layout == 'account') {
        v = AccountLayout;
      } else if (layout == 'simple') {
        v = SimpleLayout;
      } else if (layout == 'blank') {
        v = BlankLayout;
      }
      commit('updateLayout', { layout, v });
    },
    promptLogin({ dispatch, commit, state }, prompt = true) {
      ShareFunc.track("site-prompt-login", "site", "show", prompt);
      state.loginPrompt = prompt;
    },
    setScreenCategory({ dispatch, commit, state }, category) {
      state.currentScreenCategory = category;
    },
    checkIsMobile({ dispatch, commit, state }) {
      let flag = false;
      //flag = Utils.isMobile();
      //$break-xx-small: 360px;
      //$break-x-small: 480px;
      //$break-small: 768px;
      const w = window.innerWidth
      if (w <= 768) {
        flag = true;
      }
      if (flag) {
        dispatch("setShowingMobileMap", false);
      } else {
        dispatch("resetShowingMobileMap");        
      }
      state.mobile.isMobile = flag;
      //console.log("checkIsMobile", flag)
      return flag;
    },
    setShowingMobileMap({ dispatch, commit, state }, flag) {
      ShareFunc.track("site-show-mobile-map", "site", "show", flag);
      //console.log("setShowingMobileMap", flag);
      state.mobile.isShowingMap = flag;
      
      try {
        const elem = document.getElementById('map-view-holder-id');
        elem.style.visibility = flag ? "visible" : "hidden";
      } catch (e) {}

      try {
        const elem2 = document.getElementById('listing-dataview-id');
        //elem2.style.display = isShowingMap ? "none" : "block";
        //elem2.style.visibility = isShowingMap ? "hidden !important" : "visible";
        elem2.style.opacity = flag ? 0 : 1;
      } catch (e) { }
      try {
        const elem3 = document.getElementById('listing-detail-sub-holder-id');
        elem3.style.visibility = flag ? "hidden" : "visible";
      } catch (e) { }

      return flag;      
    },
    resetShowingMobileMap({ dispatch, commit, state }) {
      state.mobile.isShowingMap = false;
      //console.log("resetShowingMobileMap");
      try { document.getElementById('map-view-holder-id').style.visibility = "visible"; } catch(e) {}
      try {document.getElementById('listing-dataview-id').style.opacity = 1;} catch (e) {}
      try {document.getElementById('listing-detail-sub-holder-id').style.visibility = "visible";} catch(e) {}
    }
  },
  mutations: {
    init(state) {
      state.init = true;
    },
    updateTitle(state, title) {
      state.title = title;
    },
    updateMetadata(state, metadata) {
      state.metadata = metadata;
    },
    updateLayout(state, { layout, v }) {
      state.layout = layout;
      state.layoutView = v;
      // console.log("site module mutations", state)
    },
  },
};
