<template>
  <div class="map-view-holder" id="map-view-holder-id">
    <div class="map-view" id="map-view-id" />
    <div class="panel-holder">
      <div :class="['panel']" id="map-district-town">
        <div class="content">
          <div class="district"></div>
          <i @click="onClickDistrictLocation" class="pi pi-map"></i>
          <div class="towns"></div>
        </div>
        <button id="close-map-district-town" class="close-btn"
          @click="onClosePanelDistrict"></button>
      </div>
      <div :class="['panel', 'amenity']" id="map-nearby-amenity">
        <div class="title">
          <span class="txt"></span>
          <i class="ico location-white"></i>
        </div>
        <div class="content">...</div>
        <button id="close-map-nearby-amenity" class="close-btn"
          @click="onClosePanelAmenity"></button>
      </div>
    </div>
    <div id="tooltip-district">
      <div class="holder"><div class="content"></div></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, nextTick, ref, onUpdated } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Constant from "@/helpers/Constant";
import Utils from "@/helpers/Utils";
import router from "@/router";
import { listingService } from "@/services/service.listing";
import { addMaskComponent } from "@/components/LeafletMask";
import polyUtil from "polyline-encoded";
import MapFunc from "@/helpers/MapFunc";
import { showMessage } from "@/components/Notification";
import L from "leaflet";
import * as districtJson from "@/assets/json/district.json";
import ShareFunc from "@/helpers/ShareFunc";

addMaskComponent(L);

const MAP_DEFAULT_ZOOM = 11.7;
const MAP_LISTING_ZOOM = 15;
const MAP_LISTING_DETAIL_ZOOM = 17;
const store = useStore();
//const rListingDetail = ref(null);
//const rLoadingListingDetail = ref(false);
const sFilterData = store.state.listing.filterData;
let mapMarkerHydrationFlag = false;

let map = null;
let mapLevel = "overview";
let markerLayer;
let districtLayer;
let districtMaskLayer;
let districtOverviewLayer;
let amenityLayer = null;
let m_arr = [];
const d_arr = [];
let unsub1 = null;
let unsub2 = null;

const listingOveriew = {};
let totalListingCount = 0;
let selectedListingId;
let selectedDistrictId = null;

let tt = null;
let districtTownPanel;
let amenityPanel = null;

onUpdated(() => {});
onBeforeUnmount(() => {
  unsub1();
  unsub2();
});

onMounted(async () => {
  districtTownPanel = document.getElementById("map-district-town");
  amenityPanel = document.getElementById("map-nearby-amenity");
  tt = document.getElementById("tooltip-district");

  map = L.map("map-view-id", { zoomDelta: 0.25, zoomSnap: 0 });
  setupDistrict(map);
  setupMap(map);

  subscribeStore();
  await nextTick();
  store.dispatch("map/init");
  setInterval(() => {map.invalidateSize();}, 100);
  
});

const subscribeStore = () => {
  unsub1 = store.subscribeAction(async (action, state) => {
    // console.log("store.subscribeAction", action)
    if (action.type == "listing/searchFilterData") {
      clearMarkers();
    } else if (action.type == "map/hydrateMap") {
      const { level } = action.payload;
      mapLevel = level;
      const { id } = action.payload;
      if (level == "district") {
        store.dispatch("listing/searchDistrict", id);
        store.dispatch("map/setMapViewLevel", { level: "district", id });
      } else if (level == "listing") {
        const { listing } = action.payload;
        const d = _.find(d_arr, { district: listing.district });
        if (d) {
          clickDistrict(d);
        }
        // console.log("dddddddd",d_arr,m);
        const geojson = listingService.convertGeoJson([listing]);
        clearMarkers();
        addListings(geojson, map);
        const m = _.find(m_arr, { listingId: listing.listingId });
        if (m) {
          clickListing(m);
          // console.log("m.getLatLng()", m.getLatLng())
          map.flyTo(m.getLatLng(), MAP_LISTING_DETAIL_ZOOM);
        }
      }
    } else if (action.type == "map/setMapViewLevel") {
      const { level } = action.payload;
      const { id } = action.payload;
      mapLevel = level;

      if (level == "overview") {
        districtTownPanel.className = "panel hide";
        amenityPanel.className = "panel amenity hide";
        resetMapView();
        resetDistricts();
        store.dispatch("listing/clearDistrict");
        store.dispatch("listing/searchFilterData");
        m_arr = [];
      } else if (level == "district") {
        districtTownPanel.className = "panel stay";
        amenityPanel.className = "panel amenity hide";
        let d = null;
        districtLayer.eachLayer((layer) => {
          if (layer.id == id) {
            d = layer;
          }
        });
        // selectedDistrictId = d.id;
        clickDistrict(d);
      } else if (level == "listing") {
        districtTownPanel.className = "panel hide";
        // loynote: show/stay amenity panel after loaded
        amenityPanel.className = "panel amenity hide";
        // amenityPanel.className = "panel amenity stay";
        // loynote: needed for listing cards clicks
        //rLoadingListingDetail.value = true;
        const m = _.find(m_arr, { listingId: id });
        if (m) {
          clickListing(m);
        } else {
          mapMarkerHydrationFlag = true; // loynote: for when in overview, then click to detail
          // console.log("mapMarkerHydrationFlag")
        }
      }

      // console.log("action", level, id, action);
    } else if (action.type == "map/routeToAmenity") {
      const p = action.payload;
      drawAmentityMarker(p);
    } else if (action.type == "map/flyToListingMarker") {
      const listingId = action.payload;
      flyToListingMarker(listingId);
    } else if (action.type == "map/flyToDistrictBounds") {
      const district = action.payload;
      flyToDistrictBounds(district);
    } else if (action.type == "map/onMouseEnterCard") {
      const l = action.payload;
      if (mapLevel == "overview") {
        // console.log("d_arr", d_arr);
        const d = _.find(d_arr, { district: l.district });
        d?.fire("mouseover");
      } else {
        const m = _.find(m_arr, { listingId: l.listingId });
        const str = `_${l.listingId}`;
        // document.getElementById("anim_icon_over_01"+str).beginElement();
        try {
          document.getElementById(`anim_icon_over_01${str}`).beginElement();
        } catch (e) {}
        m?.setZIndexOffset(-1000);
      }
    } else if (action.type == "map/onMouseLeaveCard") {
      const l = action.payload;
      const str = `_${l.listingId}`;
      if (mapLevel == "overview") {
        const d = _.find(d_arr, { district: l.district });
        d?.fire("mouseout");
      } else {
        // document.getElementById("anim_icon_out_01"+str).beginElement();
        try {
          document.getElementById(`anim_icon_out_01${str}`).beginElement();
        } catch (e) {
          console.log(e);
        }
      }
    } else if (action.type == "map/onMouseEnterDistrict") {
      const d = _.find(d_arr, { district: action.payload });
      d?.fire("mouseover");
      tt.style.left = "-1000px"; // tt.style.top = '-1000px';
    } else if (action.type == "map/onMouseLeaveDistrict") {
      const d = _.find(d_arr, { district: action.payload });
      d?.fire("mouseout");
    }

    else if (action.type == "map/flyToLatLng") {
      const { lat, lng } = action.payload;
      const latlng = L.latLng(lat, lng);
      const r = Math.random() * 0.3 * (Math.round(Math.random()) ? 1 : -1);
      map.flyTo(latlng, MAP_LISTING_ZOOM+r);
    }
  });

  unsub2 = store.subscribe((mutation, state) => {
    if (mutation.type == "listing/updateListing") {
      // console.log("mutation.type", mutation, mapLevel);
      if (mapLevel == "overview") {
        return;
      }
      const geojson = listingService.convertGeoJson(mutation.payload);
      // console.log("mutation.type", mutation, geojson);
      clearMarkers();
      addListings(geojson, map);
    } else if (mutation.type == "listing/updateListingDistrictOverview") {
      //console.log("listing/updateListingDistrictOverview");
      const o = mutation.payload;
      addListingDistrictOverview(o);
    } else if (mutation.type == "listingDetail/updateListingDetail") {
      //rLoadingListingDetail.value = false;
      //rListingDetail.value = mutation.payload;
      // console.log("mutation.payload", mutation.payload)
      if (mapMarkerHydrationFlag) {
        mapMarkerHydrationFlag = false;
        const ld = mutation.payload;
        store.dispatch("map/hydrateMap", {
          level: "listing",
          id: ld.listingId,
          listing: ld,
        });
      }
    } else if (mutation.type == "listingDetail/updateNearbyAmenities") {
      if (mapLevel != "listing") return;
      const amenities = mutation.payload;
      setupNearbyAmenity(amenities);
      amenityPanel.className = "panel amenity show";
    } else if (mutation.type == "map/updateAmenityRoute") {
      const route = mutation.payload;
      drawAmentityRoute(route);
    }

    // console.log("mutation.type", mutation);
  });
};

const onClosePanelDistrict = async () => {
  const f = store.state.listing.filterData;
  const str = `/${f.offerType}/${f.marketType}/${f.propertyType}`;
  //console.log("onClosePanelDistrict", str);
  router.push(str);

};
const onClosePanelAmenity = async () => {
  const str = MapFunc.formSeoDistrictUrl(sFilterData, selectedDistrictId);
  router.push(str);
  console.log("onClosePanelAmenity", str);

  //ui bug, need to hide listing behind map while in mobile map mode
  const showingMobileMap = store.state.site.mobile.isShowingMap;
  if (showingMobileMap) {
    await Utils.sleep(100);
    store.dispatch("site/setShowingMobileMap", true);    
  }
  
};

const clearMarkers = () => {
  markerLayer?.clearLayers();
  amenityLayer?.clearLayers();
};

const clearMaskOverlay = () => {
  districtLayer?.eachLayer((d) => {
    d.selected = false;
    d.setStyle({ weight: 0, color: "#425ab7" });
  });
  districtMaskLayer?.clearLayers();
};

const resetMarkers = () => {
  markerLayer?.eachLayer((m) => {
    m.setZIndexOffset(0);
  });
};

const resetDistricts = () => {
  districtLayer.addTo(map);
  districtOverviewLayer?.clearLayers();
  districtLayer?.eachLayer((d) => {
    d.overviewLayer?.addTo(districtOverviewLayer);
    d.setInteractive(true);
  });
};

const resetMapView = () => {
  selectedListingId = null;
  selectedDistrictId = null;
  clearMarkers();
  clearMaskOverlay();
  // mapLevel = "overview";
  districtOverviewLayer.addTo(map);
  districtMaskLayer.addTo(map);
  districtLayer.addTo(map);
  districtTownPanel.className = "panel hide";
  amenityPanel.className = "panel amenity hide";

  map.flyTo(
    [Constant.SINGAPORE_CENTER[0], Constant.SINGAPORE_CENTER[1]],
    MAP_DEFAULT_ZOOM,
    { duration: 0.5 }
  );
};

const setupMap = (map) => {
  const m = map;
  amenityLayer = L.featureGroup();

  const onemap = L.tileLayer(
    "https://maps-{s}.onemap.sg/v3/Default_HD/{z}/{x}/{y}.png",
    {
      subdomains: ["a", "b", "c"],
      detectRetina: true,
      maxZoom: 20,
      minZoom: 11,
    }
  );
  const googleStreets = L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] } );
  const googleHybrid = L.tileLayer("http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}", { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] } );
  const googleSat = L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] } );
  const googleTerrain = L.tileLayer("http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}", { maxZoom: 20, subdomains: ["mt0", "mt1", "mt2", "mt3"] } );
  const openStreetMap = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { maxZoom: 20 } );
  const cartoMap = L.tileLayer("https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png", { maxZoom: 20 } );

  const baseMaps = {
    "Google Streets": googleStreets,
    "Google Sat": googleSat,
    "One Map": onemap,
    "OSM": openStreetMap,
    "Carto": cartoMap,
  };

  const bm = store.state.map.basemap;
  //console.log("basemap", bm);
  if (baseMaps[bm]) {
    m.addLayer(baseMaps[bm]);
  } else {
    m.addLayer(cartoMap);
  }
  
  
  //m.addLayer(cartoMap);
  // m.addLayer(googleStreets);
  // m.addLayer(openStreetMap);

  L.control.layers(baseMaps, null, { position: "bottomright" }).addTo(m);
  m.zoomControl.setPosition("bottomright");
  L.control.scale({ imperial: false }).addTo(map);

  // m.setMaxBounds([ Constant.SINGAPORE_TOP_LEFT, Constant.SINGAPORE_BOTTOM_RIGHT ]);
  // m.setView([Constant.SINGAPORE_CENTER[0], Constant.SINGAPORE_CENTER[1]], 12);
  m.setView([Constant.SINGAPORE_CENTER[0], Constant.SINGAPORE_CENTER[1]],  MAP_DEFAULT_ZOOM);
  m.addLayer(amenityLayer);

  // console.log("map", m)
  // map.on('zoomstart zoom zoomend', function (ev) {
  //   console.log('Zoom level: ', ev.type, map.getZoom());
  //   if (ev.type == "zoomend") {
  //     selectedDistrictZoom = map.getZoom();
  //     console.log("selectedDistrictZoom", selectedDistrictZoom);
  //   }

  // });
  m.on('baselayerchange', function (e) {
    //console.log("base map name ->", e.name);
    store.dispatch("map/storeBaseMapSetting", e.name);
    //console.log(e.layer);
  });

};
const addListings = (geojson, map) => {
  if (_.isEmpty(geojson)) return;
  const listings = geojson.features;
  // console.log("addListings", listings);
  _.orderBy(listings, (f) => f.properties.updatedAt, ["desc"]);

  const geoJsonLayer = L.geoJson(listings);
  markerLayer = new L.layerGroup();
  markerLayer.addTo(map);

  geoJsonLayer.eachLayer((layer) => {
    // markers
    if (layer.feature.geometry && layer.feature.geometry.type == "Point") {
      const ld = layer.feature.properties;
      const id = ld.listingId;
      const { offerType } = ld;
      const myIcon = MapFunc.generateMarker(id, offerType);
      const { district } = layer.feature.properties;
      const lat = layer.feature.geometry.coordinates[1];
      const lng = layer.feature.geometry.coordinates[0];

      const latlng = L.latLng(lat, lng);
      const m = L.marker(latlng, {
        icon: myIcon,
        riseOnHover: true,
        riseOffset: 1000,
      });
      // const m = L.marker(latlng, {icon:myIcon, riseOnHover:true});
      // const m = L.marker(latlng, {icon:myIcon});
      m.latlng = latlng;
      m.listingId = id;
      m.district = district;

      // const m = L.marker(latlng);
      m.addTo(markerLayer);

      // console.log("m",m);
      try {
        const str = `_${id}`;
        const holder = document.getElementById(`listing-marker-holder${str}`);
        const hotspot = holder.lastElementChild;
        // console.log("hotspot", hotspot, id)
        // hotspot.mouseover = (e) => { console.log("mouse move", id); mouseenterFeature(e, id) };z
        hotspot.onmouseenter = (e) => {
          ShareFunc.track("map-hotspot", "map", "listingId", id, "mouse-over");
          MapFunc.mouseenterFeature(e, id, listings);
          
        };
        holder.onmouseleave = (e) => {
          console.log("onmouseleave", id)
          ShareFunc.track("map-hotspot", "map", "listingId", id, "mouse-leave");
          MapFunc.mouseleaveFeature(e, id);
        };
        holder.ondblclick = (e) => {
          const event = e || window.event;
          event.stopPropagation();
        };
        // hotspot.onclick = (e) => { clickListing(e, id, m) };
        m.addEventListener("click", (e) => {
          try {
            const t = e.originalEvent.target;
            const t2 = e.originalEvent.srcElement;
            //console.log("m.addEventListener", t.classList, t2);
            if (!t.classList.contains("chevron-right") && !t.classList.contains("chevron-left")) {
              const str = MapFunc.formSeoListingUrl(ld);
              // console.log("str", str)
              router.push(str);
            } else {
              ShareFunc.track("map-hotspot-slide-image", "map", "listingId", id);
            }
          } catch (e) {}
        });
        m_arr.push(m);
      } catch (e) {
        console.log("err", e);
      }
    }
  });
};
const clickListing = async (m) => {
  resetMarkers();
  const id = m.listingId;
  //console.log("clickListing", m);
  if (selectedListingId == id) return;
  selectedListingId = id;
  const divIcons = document.querySelectorAll(".my-div-icon");
  for (let i = 0; i < divIcons.length; i++) {
    divIcons[i].classList.remove("selected");
  }
  const str = `_${id}`;
  amenityLayer?.clearLayers();
  // map.flyTo(m.latlng, MAP_LISTING_DETAIL_ZOOM);
  map.flyTo(m.latlng);
  // loynote: weird prob due to mouse out raiseOnHover logic?
  setTimeout(() => {
    m.setZIndexOffset(1000);
    const divIcon = document.getElementsByClassName(`my-div-icon${str}`)[0];
    divIcon.classList.add("selected");
    //console.log("clickingListing", divIcon);
    // m._updateZIndex(100000);
    // m.setOpacity(0.2);
  }, 100);
};

const setupDistrict = (map) => {
  const j = districtJson;
  districtLayer = new L.layerGroup();
  districtMaskLayer = new L.layerGroup();
  districtOverviewLayer = new L.layerGroup();
  districtLayer.addTo(map);
  districtMaskLayer.addTo(map);
  districtOverviewLayer.addTo(map);

  const geoJsonLayer = L.geoJson(j, {
    onEachFeature: (feature, layer) => {
      if (feature.geometry.type == "MultiPolygon") {
        let latlngs = [];
        const { id } = feature.properties;
        const polygon = feature.geometry.coordinates; // loynote: contain multiple coords
        latlngs = L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates, 2);
        const d = L.polygon(latlngs, {
          stroke: true,
          opacity: 1,
          weight: 0,
          fillOpacity: 0,
          color: "#425ab7",
          fillColor: "#ffffff",
        }).addTo(map);
        // console.log("latLngs", latlngs);
        const mask = L.mask(latlngs, {
          fillOpacity: 0.3,
          clickable: false,
          className: "mask",
          interactive: false,
        });

        d.district = id;
        d.id = id;
        d.town = feature.properties.desc;
        d.townDesc = d.town.split(",");
        d.mask = mask;
        d.listingCount = null; // assign in addListingDistrictOverview()
        d.lat = feature.properties.latitude;
        d.lng = feature.properties.longitude;
        // d.selected = false;
        d.addTo(districtLayer);

        // const m = L.marker(latlng,{icon:myIcon});

        /**/
        d.addEventListener("click", (e) => {
          // const f = store.state.listing.filterData;
          const str = MapFunc.formSeoDistrictUrl(sFilterData, d.id);
          //`${sFilterData.offerType}/${sFilterData.marketType}/${d.id}`;
          router.push(str);
        });

        d.addEventListener("mouseover", (e) => {
          if (d.selected) {
            // console.log("mouseover d", d);
            tt.style.display = "none";
            return;
          }
          if (selectedDistrictId != null) {
            districtLayer?.eachLayer((d) => {
              if (!d.selected) d.setStyle({ weight: 0 });
            });
            d.setStyle({
              weight: 2,
              opacity: 1,
              dashArray: "5, 3",
              dashOffset: "0",
            });
            // todo: solve footer mouse over tt position
            tt.style.display = "block";
            tt.firstChild.firstChild.innerHTML = `<div class='district'>District ${d.district} <i class='pi pi-home'></div>`;
            tt.firstChild.firstChild.innerHTML += `<div class='town'>${d.townDesc}</div>`;
            return;
          }
          // //console.log("d.selected", d.district, d.selected);
          d.setStyle({ weight: 3, color: "#425ab7", dashArray: "0" });
          prepareDistrictText(d);
          districtTownPanel
            ? (districtTownPanel.className = "panel show")
            : null;
          districtMaskLayer.addLayer(mask);
        });

        d.addEventListener("mouseout", (e) => {
          tt.style.display = "none";
          if (!d.selected) d.setStyle({ weight: 0 });
          if (selectedDistrictId != null) return;
          districtTownPanel.className = "panel hide";
          mask.remove();
        });

        d.addEventListener("mousemove", (e) => {
          if (selectedDistrictId == null) return;
          if (d.selected) {
            tt.style.display = "none";
          }
          // console.log("mousemove", e);
          const map = document.getElementById("map-view-id");
          const mapPos = map.getBoundingClientRect();
          // tt.style.display = "block";
          tt.style.left = `${e.originalEvent.clientX - mapPos.x}px`;
          tt.style.top = `${e.originalEvent.clientY - mapPos.y}px`;
        });

        d_arr.push(d);
      }
    },
  });
  // .addTo(map);
};

const prepareDistrictText = (d) => {
  if (districtTownPanel) {
    const desc = d.townDesc;
    districtTownPanel.firstChild.firstChild.innerHTML = `District ${d.id}`;
    districtTownPanel.firstChild.lastChild.innerHTML = desc.join(",");
  }
};

const clickDistrict = async (d) => {
  clearMarkers();
  clearMaskOverlay();
  resetDistricts();
  d.setInteractive(false);

  districtMaskLayer.addLayer(d.mask);
  districtMaskLayer.addTo(map);

  d.setStyle({ weight: 3, color: "#425ab7", dashArray: "0" });
  /// /store.dispatch("listing/searchDistrict", id);
  districtOverviewLayer.remove();

  const center = L.latLng(d.lat, d.lng);
  // console.log("selectedDistrictId", selectedDistrictId, d.id)
  if (selectedDistrictId != d.id) {
    map.flyTo(center);
  } else if (map.getZoom() > MAP_LISTING_ZOOM) {
    const r = Math.random() * 0.3 * (Math.round(Math.random()) ? 1 : -1);
    map.flyTo(map.getCenter(), map.getZoom() + r);
  }
  // if (selectedDistrictId == null) {
  //   map.fitBounds(d.getBounds(), {padding: [50,50]});
  // }
  selectedDistrictId = d.id;
  d.selected = true;
  selectedListingId = null;
  //rListingDetail.value = null;

  await nextTick();
  prepareDistrictText(d);
};
const addListingDistrictOverview = (data) => {
  districtOverviewLayer.clearLayers();
  districtLayer.eachLayer((d) => {
    d.listingCount = data[d.district] || 0;
    if (d.listingCount > 0) {
      const html = `<div class='tag-overview'>${d.listingCount} Listings</div>`;
      const myIcon = L.divIcon({ className: "tag-overview-holder", html });
      const center = L.latLng(d.lat, d.lng);
      const overview = L.marker(center, {
        icon: myIcon,
        clickable: false,
        interactive: false,
      });
      overview.district = d.district;
      d.overviewLayer = overview;
      overview.addTo(districtOverviewLayer);
      totalListingCount += d.listingCount;
    }
    // console.log(">>>",d.district, d.listingCount);
  });
};

const setupNearbyAmenity = (arr) => {
  const ld = store.getters["listingDetail/listingDetail"];
  // console.log("setupNearbyAmenity", arr, ld);

  const titleHolder = amenityPanel.getElementsByTagName("div")[0];
  const titleTxt = titleHolder.getElementsByTagName("span")[0];
  const icon = titleHolder.getElementsByTagName("i")[0];
  icon.onclick = () => {
    flyToListingMarker(ld.listingId);
  };
  const contentHolder = amenityPanel.getElementsByTagName("div")[1];

  let title = `${ld.blkNo} ${Utils.formatTitleCase(ld.streetName)} `;
  if (title.indexOf("undefined") != -1) title = "";
  titleTxt.innerHTML = title;
  const html = '<div class="header amenity">Nearby Amenities</div>';
  contentHolder.innerHTML = html;

  arr.forEach((a) => {
    const e = document.createElement("div");
    e.className = "amenity-txt";
    const str = `<i class='ico route'></i><span>${MapFunc.mapSupermarketName(
      a.name
    )}</span>`;
    e.innerHTML = str;
    e.onclick = () => {
      store.dispatch("map/routeToAmenity", {
        lat1: ld.lat,
        lng1: ld.lng,
        lat2: a.lat,
        lng2: a.lng,
      });
    };
    contentHolder.appendChild(e);
  });
};

const drawAmentityMarker = (p) => {
  amenityLayer?.clearLayers();
  const latlng = L.latLng(p.lat2, p.lng2);
  const myIcon = MapFunc.generateAmenityMarker();
  const amenity = L.marker(latlng, { icon: myIcon, interactive: false });
  amenity.addTo(amenityLayer);
};

const drawAmentityRoute = (res) => {
  if (!res || res.error) {
    // console.log("wtf!!!!");
    showMessage("Unable to get walk path", "warning");
  } else {
    amenityLayer?.clearLayers();
    const latlngs = polyUtil.decode(res.route_geometry);
    const ll = L.latLng(latlngs.at(-1)[0], latlngs.at(-1)[1]);
    const myIcon = MapFunc.generateAmenityMarker();
    const amenity = L.marker(ll, { icon: myIcon, interactive: false });
    amenity.addTo(amenityLayer);

    const n = Math.floor(latlngs.length / 2);
    const middle = L.latLng(latlngs.at(n)[0], latlngs.at(n)[1]);
    const myIcon2 = MapFunc.generateAmenityInfoMarker(res);
    const info = L.marker(middle, {
      icon: myIcon2,
      pane: "tooltipPane",
      interactive: false,
      riseOffset: 1000,
    });
    info.addTo(amenityLayer);

    const route = new L.Polyline(latlngs, {
      color: "green",
      weight: 3,
      opacity: 0.9,
      dashArray: "5, 5",
      dashOffset: "0",
      smoothFactor: 1,
    });
    const routeBg = new L.Polyline(latlngs, {
      color: "white",
      weight: 6,
      opacity: 0.9,
      smoothFactor: 1,
    });
    routeBg.addTo(amenityLayer);
    route.addTo(amenityLayer);

    const z = map.getBoundsZoom(route.getBounds());
    if (z > map.getZoom()) {
      map.flyTo(route.getBounds().getCenter());
    } else {
      map.flyToBounds(route.getBounds(), { padding: [50, 100] });
    }
  }
};

const flyToListingMarker = (id) => {
  const m = _.find(m_arr, { listingId: id });
  if (m) {
    const r = Math.random() * 0.3 * (Math.round(Math.random()) ? 1 : -1);
    map.flyTo(m.latlng, MAP_LISTING_DETAIL_ZOOM + r);
  }
};

const flyToDistrictBounds = (district) => {
  const d = _.find(d_arr, { district });
  const r = Math.random() * 10;
  map.fitBounds(d.getBounds(), { padding: [50 + r, 50 + r] });
};

const onClickDistrictLocation = () => {
  flyToDistrictBounds(selectedDistrictId);
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
//@import "@/styles/map.scss";
</style>
