//ref: https://github.com/Leaflet/Leaflet/issues/5442
const addFunc = (leafletObj) => {
  const L2 = leafletObj;
  L2.Layer.prototype.setInteractive = function (interactive) {
    if (this.getLayers) {
      this.getLayers().forEach(layer => {
          layer.setInteractive(interactive);
      });
      return;
    }
    if (!this._path) {
      return;
    }

    this.options.interactive = interactive;
    if (interactive) {
      L2.DomUtil.addClass(this._path, 'leaflet-interactive');
    } else {
      L2.DomUtil.removeClass(this._path, 'leaflet-interactive');
    }
  };
}

//Loynote: pass in L to add mask component
const addMaskComponent = (leafletObj) => {
  const L2 = leafletObj;
  addFunc(L2);
  L2.Mask = L2.Polygon.extend({
    options: {
      stroke: false,
      color: '#333',
      fillOpacity: 0.5,
      clickable: false,
      outerBounds: new L2.LatLngBounds([-90, -360], [90, 360]),
    },
  
    initialize: function (latLngs, options) {
      var outerBoundsLatLngs = [
        this.options.outerBounds.getSouthWest(),
        this.options.outerBounds.getNorthWest(),
        this.options.outerBounds.getNorthEast(),
        this.options.outerBounds.getSouthEast()
      ];
      L2.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options);	
    },
  });
  L2.mask = function (latLngs, options) {
    return new L2.Mask(latLngs, options);
  };
  
}

export { addMaskComponent };




