<template>

  <nav class="main">
    <div class="nav-holder">
      <div class="logo-holder">
        <a href="/">
          <i class="ico logo-rwr-3 logo"></i>
          <!-- <img class="logo" id="logo" :src="`./img/logo.png`"/> -->
        </a>
      </div>
      <div class="toggle-holder">
        <Toggle v-model="rOfferType" onLabel="BUY" offLabel="RENT"
          true-value="sale"  false-value="rental"/>
      </div>
      <div class="search-input-holder">
        <CustomSearchInput :pStore="store"/>
      </div>
      <div class="link-holder">
        <template v-if="cScreenCategory == 'listing'">
          <router-link to="/account/listing" custom v-slot="{navigate}" >
            <a class="nav-link" @click="navigate">List your property</a>
          </router-link>          
        </template>
        <template v-else>
          <router-link to="/" custom v-slot="{navigate}" >
            <a class="nav-link" @click="navigate">View listing</a>
          </router-link>
        </template>
      </div>
      <div class="profile-holder">
        <template v-if="sIsLoggedIn">
          <Avatar class="avatar logged-in" shape="circle" icon="pi pi-user" @mousemove="(e)=>{rProfileMenu.show(e)}"/>
            <!-- @click="router.push('/account/profile')" -->
          <Menu id="overlay_menu" ref="rProfileMenu" :model="rProfileMenuItems" :popup="true" @mouseleave="onMouseLeaveHideMenu" />
        </template>
        <template v-else>
          <Button icon="pi pi-sign-in" class="p-button-rounded sign-in-btn"  @click="onClickSignIn" />
          <!-- <Button label="Login / Register" icon="pi pi-sign-in" class="p-button-rounded sign-in-btn"  @click="onClickSignIn" /> -->
        </template>
      </div>
      <div :class="['floating-btn', rWobble ? 'wobble':'']" v-if="cShowMobileMapBtn">
        <Button :icon="'pi ' + cMobileIcon" class="p-button-raised link p-button-lg"  
        @click="onClickMobileMap" label="            "/>
      </div>
    </div>
  </nav>

</template>

<script setup>

import { ref, toRef, onMounted, watch, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import Toggle from '@vueform/toggle';
import env from '@/helpers/Environment';
import router from '@/router';
import { useRoute } from 'vue-router';
import _ from "lodash";
import Utils from '@/helpers/Utils';
import CustomSearchInput from '@/components/CustomSearchInput.vue';


    const store = useStore();
    const cUser = computed(() => store.state.account.user);
    const sFilterData = store.state.listing.filterData;
    const sIsLoggedIn = store.getters['account/isLoggedIn'];
    const cScreenCategory = computed(()=>store.state.site.currentScreenCategory);
    const rOfferType = toRef(store.state.listing.watcher, "offerType");
    const cShowMobileMapBtn = computed(()=>{
      return store.state.site.mobile.isMobile
          && cScreenCategory.value == "listing";

    })
    //const sIsMobile = store.state.site.mobile.isMobile;
    const rIsMobileMap = toRef(store.state.site.mobile, "isShowingMap");
    const cMobileIcon = computed(()=>{
      if ( rIsMobileMap.value) { return "pi-list"; }
      return "pi-map";
    })
    const rWobble = ref(true);    
    const rProfileMenu = ref(null);
    const menuItems = {
        label: computed(()=>_.startCase(cUser.value.name)),
        items: [{
          label: 'Profile',
          icon: 'pi pi-user-edit',
          command: () => { router.push('/account/profile'); },
        },
        {
          label: 'Favourite',
          icon: 'pi pi-bookmark',
          command: () => { router.push('/account/favourite'); },
        },
        {
          label: 'My Listing',
          icon: 'pi pi-list',
          command: () => { router.push('/account/listing'); },
        },
        {
          label: 'Message',
          icon: 'pi pi-file',
          command: () => { router.push('/account/message'); },
        },        
        {
          label: 'Sign Out',
          icon: 'pi pi-sign-out',
          command: () => { router.push('/account/logout'); },
        },]
    };
    
    if (cUser.value.roleAccess?.admin) {
      //console.log("menuItems", menuItems);      
      menuItems.items.splice(-1,0,{ 
        label: "Admin", 
        icon: "pi pi-fw pi-prime", 
        command: () => { router.push('/account/admin/listing'); },
      });
    }    
    const rProfileMenuItems = ref([menuItems]);

    const rData = reactive({
      search: { keyword: '', suggestions: null },
      selectedItem: null,
      //formError: "",
      //loading: false,
    });

    const unsub2 = store.subscribe((mutation, state) => {
      if (mutation.type == 'account/logout') {
        location.reload();
      }
    });

    const onToggle = (e) => {
      const route = router.currentRoute.value;
      console.log("toggle", route)
      let str = `/${rOfferType.value}/${sFilterData.marketType}/${sFilterData.propertyType}`;
      if (route.name == 'district-listing') {
        str += `/district/${route.params.pDistrict}`;
      }
      if (route.name == 'map' || route.name == 'district-listing' || route.name == "listing-detail") {
        router.push(str);
      }
    };

    watch(() => rOfferType.value, (oldValue, currentValue) => {
      //console.log("rOfferType", oldValue, currentValue)
      if (currentValue && currentValue != oldValue) {
        onToggle();
      }
    }, { immediate:true });

    //console.log(env.AWS_COGNITO_SIGNIN_URL);
    const onClickSignIn = () => {      
      window.location = env.AWS_COGNITO_SIGNIN_URL;
    };

    const onMouseLeaveHideMenu = () => {
      rProfileMenu?.value.hide();
    };

    const onClickMobileMap = async () => {
      const isShowingMap = await store.dispatch("site/setShowingMobileMap", !rIsMobileMap.value);
      if (!isShowingMap) {
        rWobble.value = false;
      }
      //console.log("isShowingMap", isShowingMap)
    }


    const onLogout = () => {
      router.push('/account/logout');
    };



</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss">
  #overlay_menu {
    //background-color: red;
    .p-submenu-header {padding: 0.5rem 1rem;}
    .p-menuitem {
      .p-menuitem-link {
        padding: 0.6rem 1rem;
        .p-menuitem-icon {color:$grey-6;}
      }
    }

  }
</style>
